//eslint-disable
import React, { Component } from 'react';
import {Form, Input, Button, Row, Col, message, Table, InputNumber, Tooltip, Modal, Card} from 'antd';
import axios from 'axios';
import {EyeOutlined} from "@ant-design/icons";
import ViewTransactionForm from "../Transaction/Commen/ViewTransactionForm";
import Item from "./Item";
import backendApiLink from "../../backendLinkConfig";

class Customer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: null,
            loading: false,
            tablePayment: [],
            isUpdateModalVisible: false,
            isViewModalVisible: false,
            selectedItem: null,
            initialValues: {},
            isSoldItemsShow: false,
        };
        this.getCustomerDetails();
        this.toggleViewModal = this.toggleViewModal.bind(this);
        this.handleViewShow = this.handleViewShow.bind(this);
    }
    formRef = React.createRef();

    toggleViewModal() {
        this.setState({
            isViewModalVisible: !this.state.isViewModalVisible,
            selectedItem: null,
        });
    }
    handleViewShow(row) {
        //console.log('row', row);
        this.setState({
            selectedItem: row,
            isViewModalVisible: true,
        });
        //console.log('selectedItem', this.state.selectedItem);
    }

    async getCustomerDetails() {
        this.setState({ loading: true });

        try {
            //console.log('this.props.customerId', this.props);
            const response = await axios.post(`${backendApiLink}/getCustomerDetails`, {
                CUSTOMER_ID: this.props.customerId,
            });


            if (response.data.success) {
                const items = response.data.result;
                //console.log('items', items);
                this.setState({ initialValues: items });

                // set the form values
                this.formRef.current.setFieldsValue({
                    NAME: items.NAME,
                    PHONE_NUMBER: items.PHONE_NUMBER,
                    NIC: items.NIC,
                    COMPANY: items.COMPANY,
                    ADDRESS: items.ADDRESS,
                });

                this.getCustomerTransactions();
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    async getCustomerTransactions() {
        this.setState({ loading: true });

        if(this.state.initialValues.TYPE === 'Seller'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerSellerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Buyer'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerBuyerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Sales Person'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerSalesPersonTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Partner'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerPartnerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Preformer'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerPreformerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'C&P'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerCPTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Electric'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerElectricTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Heat T'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerHeatTTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }

    }


    render() {
        const { form } = this.state;
        const { type } = this.props;

        const inputStyle = {
            pointerEvents: "none",
            background: "#ffffff",
            width: "100%",
        };
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        return (
            <Form ref={this.formRef} layout="vertical">
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="NAME"
                            label="Customer Name"
                            rules={[{ required: true, message: 'Please enter customer name' }]}
                        >
                            <Input placeholder="Enter customer name" style={inputStyle} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item name="PHONE_NUMBER" label="Phone Number">
                            <Input placeholder="Enter phone number" style={inputStyle} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item name="NIC" label="NIC Number">
                            <Input placeholder="Enter NIC number" style={inputStyle} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="COMPANY"
                            label="Company"
                        >
                            <Input placeholder="Enter a company" style={inputStyle} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="ADDRESS" label="Customer Address">
                            <Input.TextArea rows={4} placeholder="Enter customer address" style={inputStyle} />
                        </Form.Item>
                    </Col>
                </Row>


                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={
                        <Button
                            style={{
                                color: '#FFFFFF',
                                background: '#000000',
                                border: 'none',
                                borderRadius: '5px',
                                // padding: '8px 16px',
                                cursor: 'default',
                            }}
                            onClick={() => {}}

                        >
                            Details
                        </Button>
                    }
                >
                    <div className="table-responsive">
                        {(this.state.initialValues.TYPE === 'Seller' || this.state.initialValues.TYPE === 'Buyer')  ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="TRANSACTION_ID"
                                columns={[
                                    {
                                        title: 'Transaction Code',
                                        dataIndex: 'CODE',
                                        render: (text, record) => (
                                            record.DUE === true ? (
                                                <span style={{ color: 'red' }}>{record.CODE} - {record.NO_OF_LATE_DAYS} Days Late</span>
                                            ) : (
                                                <span>{record.CODE}</span>
                                            )
                                        ),
                                    },
                                    {
                                        title: 'Date',
                                        dataIndex: 'DATE',
                                        render: (row) => (
                                            <span> {new Date(row).toLocaleDateString()}</span>
                                        ),
                                    },
                                    {
                                        title: 'Reference Item',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    // {
                                    //     title: 'Initial Payment',
                                    //     dataIndex: 'PAYMENT_AMOUNT',
                                    //     render: (text, record) => {
                                    //         return (
                                    //             <InputNumber readOnly
                                    //                          defaultValue={text}
                                    //                          formatter={(value) =>
                                    //                              `Rs. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    //                          }
                                    //                          parser={(value) => value.replace(/Rs.\s?|(,*)/g, '')}
                                    //             />
                                    //         );
                                    //     },
                                    // },
                                    {
                                        title: 'Amount',
                                        dataIndex: 'AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div>Rs. {record.AMOUNT}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Amount Settled',
                                        dataIndex: 'AMOUNT_SETTLED',
                                        render: (text, record) => (
                                            <span>
                <div style={{ color: 'green' }}>Rs. {record.AMOUNT_SETTLED}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Due Amount',
                                        dataIndex: 'DUE_AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div style={{ color: 'red' }}>Rs. {record.DUE_AMOUNT}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Action',
                                        width: '120px',
                                        align: 'center',
                                        render: (row) => (
                                            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip title="View">
                    <Button
                        type="default"
                        icon={<EyeOutlined />}
                        size="large"
                        style={buttonStyle}
                        onClick={() => this.handleViewShow(row)}
                    />
                  </Tooltip>
                </span>
                                        ),
                                    },
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                                expandedRowRender={(record) => (
                                    record.PAYMENTS && record.PAYMENTS.length > 0 ? (
                                        <Table
                                            size="small"
                                            rowKey="TRANSACTION_ID"
                                            columns={[
                                                {
                                                    title: 'Transaction Code',
                                                    dataIndex: 'CODE',
                                                },
                                                {
                                                    title: 'Date',
                                                    dataIndex: 'DATE',
                                                    render: (row) => (
                                                        <span> {new Date(row).toLocaleDateString()}</span>
                                                    ),
                                                },
                                                {
                                                    title: 'Method',
                                                    dataIndex: 'METHOD'
                                                },
                                                {
                                                    title: 'Amount',
                                                    dataIndex: 'PAYMENT_AMOUNT'
                                                },
                                                {
                                                    title: 'Note',
                                                    dataIndex: 'COMMENTS'
                                                },
                                            ]
                                            }
                                            dataSource={record.PAYMENTS}
                                            pagination={false}
                                        >
                                        </Table>
                                    ) : null
                                )}
                            />
                        ) : null}
                        {this.state.initialValues.TYPE === 'Partner' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Reference Item',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Status',
                                        dataIndex: 'STATUS',
                                    },
                                    {
                                        title: 'Share Percentage',
                                        dataIndex: 'SHARE_PERCENTAGE',
                                        render: (text, record) => (
                                            <span>
                <div>{record.SHARE_PERCENTAGE}%</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Other Shares',
                                        dataIndex: 'OTHER_SHARES',
                                    }
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
                        {this.state.initialValues.TYPE === 'Sales Person' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Reference Item',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Type',
                                        dataIndex: 'TYPE',
                                    },
                                    {
                                        title: 'No of Pieces',
                                        dataIndex: 'PIECES',
                                    },
                                    {
                                        title: 'Weight',
                                        dataIndex: 'WEIGHT',
                                    }
                                ]}
                                dataSource={this.state.tablePayment.withSalesPerson}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
                        {this.state.initialValues.TYPE === 'Sales Person' ? (
                            < Button type="default" style={{ margin: '15px' }}
                                     onClick={() => this.setState({ isSoldItemsShow: !this.state.isSoldItemsShow })}>
                                {this.state.isSoldItemsShow ? 'Hide Sold Items' : 'Show Sold Items'}
                            </Button>
                        ) : null}
                        {this.state.isSoldItemsShow && this.state.initialValues.TYPE === 'Sales Person' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Items Sold',
                                        dataIndex: 'CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Buyer',
                                        dataIndex: 'C_NAME',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.CUSTOMER_ID)}>
                                <span>
                <div>{record.C_NAME}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Date Sold',
                                        dataIndex: 'DATE_SOLD',
                                        render: (row) => (
                                            <span> {new Date(row).toLocaleDateString()}</span>
                                        ),
                                    },
                                    {
                                        title: 'Sold Amount',
                                        dataIndex: 'SOLD_AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div>Rs. {record.SOLD_AMOUNT}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Due Amount',
                                        dataIndex: 'DUE_AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div style={{ color: 'red' }}>Rs. {record.DUE_AMOUNT}</div>
            </span>
                                        ),
                                    }
                                ]}
                                dataSource={this.state.tablePayment.sold}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}


                        {this.state.initialValues.TYPE === 'Preformer' || this.state.initialValues.TYPE === 'Electric' || this.state.initialValues.TYPE === 'Heat T' || this.state.initialValues.TYPE === 'C&P' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Items With Customer',
                                        dataIndex: 'CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>
                                <span>
                <div>{record.CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Type',
                                        dataIndex: 'TYPE',
                                    },
                                    {
                                        title: 'No of Pieces',
                                        dataIndex: 'PIECES',
                                    },
                                    {
                                        title: 'Weight',
                                        dataIndex: 'WEIGHT',
                                    }
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
                    </div>
                    <Modal
                        title="View Transaction"
                        visible={this.state.isViewModalVisible}
                        onCancel={this.toggleViewModal}
                        footer={null}
                        width={1250}
                    >
                        {this.state.selectedItem && (
                            <ViewTransactionForm
                                key={this.state.selectedItem.TRANSACTION_ID}
                                initialValues={this.state.selectedItem}
                            />
                        )}
                    </Modal>

                    <Modal
                        title="View Item"
                        visible={this.state.isViewItemModalVisible}
                        onCancel={() => this.setState({ isViewItemModalVisible: false })}
                        footer={null}
                        width={1250}
                    >
                        {this.state.selectedRefferenceItem && (
                            <Item
                                key={this.state.selectedRefferenceItem} // Pass a key to ensure a new instance is created
                                itemId={this.state.selectedRefferenceItem}
                            />
                        )}
                    </Modal>
                </Card>
            </Form>
        );
    }
}

export default Customer;
