 /* eslint-disable */
import React, { useState } from 'react';
import {Form, Input, Button, Checkbox, message, Select} from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Change import statement
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './styles.css';
import Cookies from 'js-cookie';
// import backendApiLink from "../../backendLinkConfig";
let backendApiLink = null;



const Login = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory(); // Change to useHistory

    const initialDatabaseValue = Cookies.get('database') !== undefined ? Cookies.get('database') : 'Database1';

    const onFinish = async (values) => {
        setLoading(true);

        try {
            //console.log('Login values:', values);
            //clear the cookies
            // await Cookies.remove('rememberedUser');
            await Cookies.remove('database');

            //add database cookie from value.database
            await Cookies.set('database', values.database, { expires: 2 });
            if(values.database === 'Database1'){
                backendApiLink = 'https://nihalgems.online/api';
                // backendApiLink = 'http://localhost:3001/api';
            }
            else if(values.database === 'Database2'){
                backendApiLink = 'https://nihalgems.online/api2';
            }
            const response = await axios.post(`${backendApiLink}/login`, values);

            if (response.status === 200) {
                message.success('Login successful');
                const { USER_ID, NAME,EMAIL,ROLE,PHOTO,CUSTOMER_ID  } = response.data.user;

                if (values.remember) {
                    Cookies.set('rememberedUser', JSON.stringify({ USER_ID, NAME,EMAIL,ROLE,PHOTO,CUSTOMER_ID }), { expires: 2 });
                    // console.log('rememberedUser', { USER_ID, NAME,EMAIL,ROLE,CUSTOMER_ID });
                }
                // Navigate to the dashboard using history.push
                // history.push('/dashboard');
                window.location.href = "/dashboard";
            } else {
                message.error('Invalid username or password');
            }
        } catch (error) {
            console.error('Login error:', error);
            message.error('Login failed. Please try again.');
        }

        setLoading(false);
    };

    const onFinishFailed = errorInfo => {
        //console.log('Failed:', errorInfo);
    };

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img src="https://i.ibb.co/ykWXkGt/Untitled-design-4.png" alt="Login"/>
                </div>
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="login-form"
                >
                    <p className="form-title">Welcome To Nihal Gems Management System</p>
                    <p>Login to the Dashboard</p>
                    <Form.Item
                        name="database"
                        rules={[{ required: true, message: 'Please select a database!' }]}
                        initialValue={initialDatabaseValue}
                    >
                        <Select
                            placeholder="Select Database"
                            style={{
                                borderRadius: '6px',
                                height: '50px',
                                position: 'relative',
                            }}
                        >
                            <Option value="Database1">Database1</Option>
                            <Option value="Database2">Database2</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="user"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            LOGIN
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
