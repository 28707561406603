//eslint-disable
import React, { Component } from 'react';
import {Form, Input, Button, Row, Col, Table, InputNumber, Tooltip, Modal, Card} from 'antd';
import axios from 'axios';
import {EyeOutlined} from "@ant-design/icons";
import ViewTransactionForm from "../Transaction/Commen/ViewTransactionForm";
import Item from "./Item";
import ItemURL from "./ItemURL";
// import backendApiLink from "../../backendLinkConfig";


class CustomerURL extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: null,
            loading: false,
            tablePayment: [],
            isUpdateModalVisible: false,
            isViewModalVisible: false,
            selectedItem: null,
            initialValues: {},
            backendApiLink: 'https://nihalgems.online/api',
            databaseNo: null,
        };
        this.getCustomerDetails();
        this.toggleViewModal = this.toggleViewModal.bind(this);
        this.handleViewShow = this.handleViewShow.bind(this);
    }
    formRef = React.createRef();

    toggleViewModal() {
        this.setState({
            isViewModalVisible: !this.state.isViewModalVisible,
            selectedItem: null,
        });
    }
    handleViewShow(row) {
        //console.log('row', row);
        this.setState({
            selectedItem: row,
            isViewModalVisible: true,
        });
        //console.log('selectedItem', this.state.selectedItem);
    }

    decodeBase64 = (text) => atob(text);

    async getCustomerDetails() {
        // console.log('getModelItemDetails');
        // console.log('this.props', this.props);
        this.setState({ loading: true });

        const { match: { params } } = this.props;
        const { id } = params;
        const databaseNo = id.split('.')[0];
        const customerId = id.split('.')[1];
        // console.log('customerId', customerId);
        // console.log('databaseNo', databaseNo);
        this.state.databaseNo = id.split('.')[0];
        // console.log('this.state.databaseNo', this.state.databaseNo);


        try {
            //console.log('this.props.customerId', this.props);
            const decodedId = this.decodeBase64(customerId);
            let response = null;
            if(databaseNo === '1'){
                response = await axios.post(`https://nihalgems.online/api/getCustomerDetails`, {
                    CUSTOMER_ID: decodedId,
                });
                // response = await axios.post(`http://localhost:3001/api/getCustomerDetails`, {
                //     CUSTOMER_ID: decodedId,
                // });
            }
            else if(databaseNo === '2'){
                response = await axios.post(`https://nihalgems.online/api2/getCustomerDetails`, {
                    CUSTOMER_ID: decodedId,
                });
            }
            // response = await axios.post(`${backendApiLink}/getCustomerDetails`, {
            //     CUSTOMER_ID: decodedId,
            // });


            if (response.data.success) {
                const items = response.data.result;
                console.log('items', items);
                this.setState({ initialValues: items });
                this.getCustomerTransactions();
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    async getCustomerTransactions() {
        this.setState({ loading: true });
        const { match: { params } } = this.props;
        const { id } = params;
        const databaseNo = id.split('.')[0];
        if(databaseNo === '1'){
            this.setState({ backendApiLink: 'https://nihalgems.online/api' });
            // this.setState({ backendApiLink: 'http://localhost:3001/api' });
        }
        else if(databaseNo === '2'){
            this.setState({ backendApiLink: 'https://nihalgems.online/api2' });
        }

        const { backendApiLink } = this.state;

        // console.log('this.state.backendApiLink', this.state.backendApiLink);

        if(this.state.initialValues.TYPE === 'Seller'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerSellerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Buyer'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerBuyerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Sales Person'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerSalesPersonTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Partner'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerPartnerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Preformer'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerPreformerTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'C&P'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerCPTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Electric'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerElectricTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }
        else if(this.state.initialValues.TYPE === 'Heat T'){
            try {
                const response = await axios.post(`${backendApiLink}/getCustomerHeatTTransactions`, {
                    CUSTOMER_ID: this.state.initialValues.CUSTOMER_ID,
                });

                if (response.data.success) {
                    const items = response.data.result;
                    //console.log('items', items);
                    this.setState({ tablePayment: items });
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                this.setState({
                    loading: false,
                });
            }
        }

    }


    render() {

        return (
            <>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Name: {this.state.initialValues.NAME ? this.state.initialValues.NAME : 'N/A'}</span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Phone Number: {this.state.initialValues.PHONE_NUMBER ? this.state.initialValues.PHONE_NUMBER : 'N/A'}</span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>NIC: {this.state.initialValues.NIC ? this.state.initialValues.NIC : 'N/A'}</span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Type: {this.state.initialValues.TYPE ? this.state.initialValues.TYPE : 'N/A'}</span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Company: {this.state.initialValues.COMPANY ? this.state.initialValues.COMPANY : 'N/A'}</span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Address: {this.state.initialValues.ADDRESS ? this.state.initialValues.ADDRESS : 'N/A'}</span>
                    </Col>
                </Row>


                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title={
                        <Button
                            style={{
                                color: '#FFFFFF',
                                background: `#000000`,
                                border: 'none',
                                borderRadius: '5px',
                                // padding: '8px 16px',
                                cursor: 'default',
                            }}
                            onClick={() => {}}

                        >
                            Details
                        </Button>
                    }
                >
                    <div className="table-responsive">
                        {(this.state.initialValues.TYPE === 'Seller' || this.state.initialValues.TYPE === 'Buyer') ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="TRANSACTION_ID"
                                columns={[
                                    {
                                        title: 'Transaction Code',
                                        dataIndex: 'CODE',
                                        render: (text, record) => (
                                            record.DUE === true ? (
                                                <span style={{ color: 'red' }}>{record.CODE} - {record.NO_OF_LATE_DAYS} Days Late</span>
                                            ) : (
                                                <span>{record.CODE}</span>
                                            )
                                        ),
                                    },
                                    {
                                        title: 'Date',
                                        dataIndex: 'DATE',
                                        render: (row) => (
                                            <span> {new Date(row).toLocaleDateString()}</span>
                                        ),
                                    },
                                    {
                                        title: 'Reference Item',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                   >
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    // {
                                    //     title: 'Initial Payment',
                                    //     dataIndex: 'PAYMENT_AMOUNT',
                                    //     render: (text, record) => {
                                    //         return (
                                    //             <InputNumber readOnly
                                    //                          defaultValue={text}
                                    //                          formatter={(value) =>
                                    //                              `Rs. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    //                          }
                                    //                          parser={(value) => value.replace(/Rs.\s?|(,*)/g, '')}
                                    //             />
                                    //         );
                                    //     },
                                    // },
                                    {
                                        title: 'Amount',
                                        dataIndex: 'AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div>Rs. {record.AMOUNT}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Amount Settled',
                                        dataIndex: 'AMOUNT_SETTLED',
                                        render: (text, record) => (
                                            <span>
                <div style={{ color: 'green' }}>Rs. {record.AMOUNT_SETTLED}</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Due Amount',
                                        dataIndex: 'DUE_AMOUNT',
                                        render: (text, record) => (
                                            <span>
                <div style={{ color: 'red' }}>Rs. {record.DUE_AMOUNT}</div>
            </span>
                                        ),
                                    },
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                                expandedRowRender={(record) => (
                                    record.PAYMENTS && record.PAYMENTS.length > 0 ? (
                                        <Table
                                            size="small"
                                            rowKey="TRANSACTION_ID"
                                            columns={[
                                                {
                                                    title: 'Transaction Code',
                                                    dataIndex: 'CODE',
                                                },
                                                {
                                                    title: 'Date',
                                                    dataIndex: 'DATE',
                                                    render: (row) => (
                                                        <span> {new Date(row).toLocaleDateString()}</span>
                                                    ),
                                                },
                                                {
                                                    title: 'Method',
                                                    dataIndex: 'METHOD'
                                                },
                                                {
                                                    title: 'Amount',
                                                    dataIndex: 'PAYMENT_AMOUNT'
                                                },
                                                {
                                                    title: 'Note',
                                                    dataIndex: 'COMMENTS'
                                                },
                                            ]
                                            }
                                            dataSource={record.PAYMENTS}
                                            pagination={false}
                                        >
                                        </Table>
                                    ) : null
                                )}
                            />
                        ) : null}
                        {this.state.initialValues.TYPE === 'Partner' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Reference Item',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    >
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Status',
                                        dataIndex: 'STATUS',
                                    },
                                    {
                                        title: 'Share Percentage',
                                        dataIndex: 'SHARE_PERCENTAGE',
                                        render: (text, record) => (
                                            <span>
                <div>{record.SHARE_PERCENTAGE}%</div>
            </span>
                                        ),
                                    },
                                    {
                                        title: 'Other Shares',
                                        dataIndex: 'OTHER_SHARES',
                                    }
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
                        {this.state.initialValues.TYPE === 'Sales Person' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Items With Sales Person',
                                        dataIndex: 'ITEM_CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    onClick={() => this.handleViewShow(record)}
                                                    >
                                <span>
                <div>{record.ITEM_CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Type',
                                        dataIndex: 'DISPLAY_TYPE',
                                    },
                                    {
                                        title: 'No of Pieces',
                                        dataIndex: 'PIECES',
                                    },
                                    {
                                        title: 'Weight',
                                        dataIndex: 'WEIGHT',
                                    }
                                ]}
                                dataSource={this.state.tablePayment.withSalesPerson}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
            {/*            {this.state.initialValues.TYPE === 'Sales Person' ? (*/}
            {/*                < Button type="default" style={{ margin: '15px' }}*/}
            {/*                         onClick={() => this.setState({ isSoldItemsShow: !this.state.isSoldItemsShow })}>*/}
            {/*                    {this.state.isSoldItemsShow ? 'Hide Sold Items' : 'Show Sold Items'}*/}
            {/*                </Button>*/}
            {/*            ) : null}*/}
            {/*            {this.state.isSoldItemsShow && this.state.initialValues.TYPE === 'Sales Person' ? (*/}
            {/*                <Table*/}
            {/*                    className="ant-border-space"*/}
            {/*                    size="small"*/}
            {/*                    style={{ margin: '15px' }}*/}
            {/*                    rowKey="ITEM_ID_AI"*/}
            {/*                    columns={[*/}
            {/*                        {*/}
            {/*                            title: 'Items Sold',*/}
            {/*                            dataIndex: 'CODE',*/}
            {/*                            render: (text, record) => (*/}
            {/*                                <Button type="default" style={{ height: 'auto' }}*/}
            {/*                                        onClick={() => this.showReferenceItem(record.ITEM_ID_AI)}>*/}
            {/*                    <span>*/}
            {/*    <div>{record.ITEM_CODE}</div>*/}
            {/*                    </span>*/}
            {/*                                </Button>*/}
            {/*                            ),*/}
            {/*                        },*/}
            {/*                        {*/}
            {/*                            title: 'Buyer',*/}
            {/*                            dataIndex: 'C_NAME',*/}
            {/*                            render: (text, record) => (*/}
            {/*                                <Button type="default" style={{ height: 'auto' }}*/}
            {/*                                        onClick={() => this.showReferenceItem(record.CUSTOMER_ID)}>*/}
            {/*                    <span>*/}
            {/*    <div>{record.C_NAME}</div>*/}
            {/*                    </span>*/}
            {/*                                </Button>*/}
            {/*                            ),*/}
            {/*                        },*/}
            {/*                        {*/}
            {/*                            title: 'Date Sold',*/}
            {/*                            dataIndex: 'DATE_SOLD',*/}
            {/*                            render: (row) => (*/}
            {/*                                <span> {new Date(row).toLocaleDateString()}</span>*/}
            {/*                            ),*/}
            {/*                        },*/}
            {/*                        {*/}
            {/*                            title: 'Sold Amount',*/}
            {/*                            dataIndex: 'SOLD_AMOUNT',*/}
            {/*                            render: (text, record) => (*/}
            {/*                                <span>*/}
            {/*    <div>Rs. {record.SOLD_AMOUNT}</div>*/}
            {/*</span>*/}
            {/*                            ),*/}
            {/*                        },*/}
            {/*                        {*/}
            {/*                            title: 'Due Amount',*/}
            {/*                            dataIndex: 'DUE_AMOUNT',*/}
            {/*                            render: (text, record) => (*/}
            {/*                                <span>*/}
            {/*    <div style={{ color: 'red' }}>Rs. {record.DUE_AMOUNT}</div>*/}
            {/*</span>*/}
            {/*                            ),*/}
            {/*                        }*/}
            {/*                    ]}*/}
            {/*                    dataSource={this.state.tablePayment.sold}*/}
            {/*                    pagination={true}*/}
            {/*                    loading={this.state.loading}*/}
            {/*                />*/}
            {/*            ) : null}*/}


                        {this.state.initialValues.TYPE === 'Preformer' || this.state.initialValues.TYPE === 'Electric' || this.state.initialValues.TYPE === 'Heat T' || this.state.initialValues.TYPE === 'C&P' ? (
                            <Table
                                className="ant-border-space"
                                size="small"
                                style={{ margin: '15px' }}
                                rowKey="ITEM_ID_AI"
                                columns={[
                                    {
                                        title: 'Items With Customer',
                                        dataIndex: 'CODE',
                                        render: (text, record) => (
                                            <Button type="default" style={{ height: 'auto' }}
                                                    >
                                <span>
                <div>{record.CODE}</div>
                                </span>
                                            </Button>
                                        ),
                                    },
                                    {
                                        title: 'Item Type',
                                        dataIndex: 'TYPE',
                                    },
                                    {
                                        title: 'No of Pieces',
                                        dataIndex: 'PIECES',
                                    },
                                    {
                                        title: 'Weight',
                                        dataIndex: 'WEIGHT',
                                    }
                                ]}
                                dataSource={this.state.tablePayment}
                                pagination={true}
                                loading={this.state.loading}
                            />
                        ) : null}
                    </div>
                </Card>
                <Modal
                    title="Item Details"
                    visible={this.state.isViewModalVisible}
                    onCancel={this.toggleViewModal}
                    footer={null}
                    width={1000}
                >
                    <ItemURL
                        key={this.state.selectedItem ? this.state.selectedItem.ITEM_CODE : null}
                        code={this.state.selectedItem ? this.state.databaseNo + '.' + this.state.selectedItem.ITEM_CODE : null}
                    />
                </Modal>

            </>);
    }
}

export default CustomerURL;
