// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import {Form, Input, Button, Row, Col, message, Select, InputNumber, DatePicker} from 'antd';
import axios from 'axios';
import backendApiLink from "../../../backendLinkConfig";
import moment from "moment";

const { Option } = Select;

const UpdateLoans = ({ initialValues, onUpdate, onCancel }) => {
    const [form] = Form.useForm();
    const [partnerOptions, setReferenceOptions] = useState([]);

    useEffect(() => {
        // Set initial values when the form mounts
        form.setFieldsValue({
            ...initialValues,
            DATE : moment(initialValues.DATE),
        });

        // Fetch reference options
        fetchReferenceOptions();
    }, [form, initialValues]);

    const fetchReferenceOptions = async () => {
        try {
            const response = await axios.post(`${backendApiLink}/getAllCustomers`);
            //console.log('response', response);
            const options = response.data.result.filter((ref) => ref.TYPE === 'Partner').map((ref) => ({
                value: ref.CUSTOMER_ID,
                label: ref.NAME,
            }));
            setReferenceOptions(options);
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    };

    const handleSubmit = async (values) => {
        try {
            // Add IS_ACTIVE to the values object

            const updatedValues = {
                ...values,
                LOANS_ID: initialValues.LOANS_ID, // Pass the CUSTOMER_ID to identify the Loans
            };

            // Make API call to update Loans
            const response = await axios.post(`${backendApiLink}/updateLoans`, updatedValues);

            if (response.data.success) {
                message.success('Loans updated successfully');

                // Notify parent component (Loans) about the update
                onUpdate();

                // Close the modal
                onCancel();

                // You can reset the form if needed
                form.resetFields();
            } else {
                message.error('Failed to update Loans');
            }
        } catch (error) {
            console.error('Error updating Loans:', error);
            message.error('Internal server error');
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="DATE"
                            label="Date"
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                        name="AMOUNT"
                        label="Amount (RS)"
                        rules={[{ required: true, message: 'Please enter amount' }]}
                    >
                        <InputNumber min={0} step={0.01} placeholder="Enter Amount" style={{ width : '100%' }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item name="METHOD" label="Method"
                               rules={[{ required: true, message: 'Please select method' }]}
                               initialValue={'Cash'}>
                        <Select
                            placeholder="Method"
                            style={{ width: '100%'}}
                            allowClear
                            showSearch
                        >
                            <Option value="Cash">Cash</Option>
                            <Option value="Bank">Bank</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item name="CUSTOMER" label="Customer"
                               rules={[{ required: true, message: 'Please select customer' }]} >
                        <Select
                            placeholder="Select Customer"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option.key
                                    ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false) ||
                                (option.title
                                    ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false)
                            }
                        >
                            {partnerOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item name="REASON" label="Reason">
                        <Input.TextArea rows={4} placeholder="Enter remarks" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update Loans Details
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default UpdateLoans;
