// /* eslint-disable */
import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Upload,
  Form,
  Input,
  message,
  Modal,
} from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  UploadOutlined,
  WhatsAppOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import BgProfile from "../../assets/images/bg-profile.jpg";
import Cookies from "js-cookie";
import axios from "axios";
import Password from "antd/es/input/Password";
import backendApiLink from "../../backendLinkConfig";

class Yasiru extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: false,
      loading: false,
      isModalVisible: false,
      isModalVisibleQR: false,
      fileList2: [],
      previewVisible2: false,
      imgBBLink2: "",
    };
    this.formRef = React.createRef();
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isModalVisibleQR: false });
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };


  createVCard = () => {
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:Yasiru Jayathilaka
TEL;TYPE=WORK,VOICE:+94777772334
EMAIL:yasiru@nihalgems.com
ADR:244, Colombo Road, Moragahayata, Rathnapura, Sri Lanka
END:VCARD
    `;
    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Yasiru_Jayathilaka.vcf';
    a.click();
    URL.revokeObjectURL(url);
  };

  sendWhatsAppMessage = () => {
    const url = 'https://api.whatsapp.com/qr/ZRMKONUIQ6BTL1?autoload=1&app_absent=0';
    window.open(url, '_blank');
  };

    QRMoadalVisible = () => {
    this.setState({ isModalVisibleQR: true });
    };



  render() {
    const { form, isModalVisible,isModalVisibleQR } = this.state;

    let PHOTO1 = "https://i.ibb.co/YySdxGJ/user-1.png";
    let EMAIL1 = "";

    return (
        <>
          <div
              className="profile-nav-bg"
              style={{ backgroundImage: "url(" + BgProfile + ")" }}
          ></div>

          <Card
              className="card-profile-head"
              bodyStyle={{ display: "none" }}
              title={
                <Row justify="space-between" align="middle" gutter={[24, 0]}>
                  <Col span={24} md={12} className="col-info">
                    <Avatar.Group>
                      <Avatar
                          size={74}
                          shape="square"
                          src={PHOTO1}
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            cursor: "pointer",
                          }}
                          onClick={this.showModal}
                      />

                      <div className="avatar-info">
                        <h4 className="font-semibold m-0">Yasiru Jayathilaka</h4>
                        <p>Director (Nihal Gems pvt Ltd)</p>
                      </div>
                    </Avatar.Group>
                  </Col>

                  {/* Modal for enlarged image */}
                  <Modal
                      visible={isModalVisible}
                      onCancel={this.handleCancel}
                      footer={null}
                      centered
                  >
                    <img
                        src={PHOTO1}
                        alt="Enlarged"
                        style={{ width: "100%" }}
                    />
                  </Modal>
                  {/* Modal for enlarged QR code */}
                     <Modal
                        visible={isModalVisibleQR}
                        onCancel={this.handleCancel}
                        footer={null}
                        centered
                    >
                      <img
                          src="https://i.ibb.co/6nqZzrJ/Whats-App-Image-2024-06-30-at-10-52-33-AM.jpg"
                          alt="Enlarged"
                          style={{ width: "100%" }}
                      />
                    </Modal>
                </Row>
              }
          ></Card>

          <Row gutter={[16, 16]} justify="left" align="top">
            <Col span={24} md={24} className="mb-24">
              <Card
                  bordered={false}
                  className="header-solid h-full card-profile-information"
              >
                <Descriptions>
                  <Descriptions.Item label="Name" span={3}>
                    Yasiru Jayathilaka
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone" span={3}>
                    <a href="tel:+94777772334">+94 77 777 2334</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Email" span={3}>
                    <a href="mailto:yasiru@nihalgems.com">yasiru@nihalgems.com</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Address" span={3}>
                    244, Colombo Road, Moragahayata, Rathnapura, Sri Lanka
                  </Descriptions.Item>
                  <Descriptions.Item label="Save Contact Details" span={3}>
                    <Button type="primary" onClick={this.createVCard}>
                        Save Contact Details
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item label="Send WhatsApp Message" span={3}>
                    <Button type="primary" onClick={this.sendWhatsAppMessage}>
                      {<WhatsAppOutlined />}
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item label="Send Wechat Message" span={3}>
                    <Button type="primary" onClick={this.QRMoadalVisible}>
                      {<WechatOutlined />}
                    </Button>
                  </Descriptions.Item>
                  {/*<Descriptions.Item label="Social" span={3}>*/}
                  {/*  <a href="#" className="mx-5 px-5">*/}
                  {/*    {<TwitterOutlined />}*/}
                  {/*  </a>*/}
                  {/*  <a href="#" className="mx-5 px-5">*/}
                  {/*    {<FacebookOutlined style={{ color: "#344e86" }} />}*/}
                  {/*  </a>*/}
                  {/*  <a href="#" className="mx-5 px-5">*/}
                  {/*    {<InstagramOutlined style={{ color: "#e1306c" }} />}*/}
                  {/*  </a>*/}
                  {/*</Descriptions.Item>*/}
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </>
    );
  }
}

export default Yasiru;
