// /* eslint-disable */
import React, { Component } from 'react';
import {Form, Input, Button, Col, Row, message, Select, InputNumber, DatePicker} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import backendApiLink from "../../../backendLinkConfig";

const { Option } = Select;

class AddLoans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CustomerOptions: [],
            partnerOptions: [],
            showLoanRef: this.props.loanType === 'Pay Loan' ? true : false,
            loanRefOptions: [],
            loanRefDetails: [],

        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        try {
            const CustomerOptions = await this.fetchCustomerOptions();
            this.setState({ CustomerOptions });
            const loanRefOptions = await this.fetchLoanRefOptions();
            this.setState({ loanRefOptions });
            if(this.props.loanRef){
                this.handleLoanRefSelect(this.props.loanRef);
            }
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    handleTypeChange = (value) => {
        if (value === 'Get Loan') {
            this.setState({ showLoanRef: false });
        } else {
            this.setState({ showLoanRef: true });
        }
    }

    async fetchLoanRefOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getAllLoans`);

            //console.log("response", response);
            return response.data.result.filter((loan) => loan.TYPE === 'Get Loan').map((loan) => ({
                value: loan.LOANS_ID,
                label: loan.CODE,
            }));
        } catch (error) {
            console.error("Error fetching loan ref options:", error);
            return [];
        }
    }

    async fetchCustomerOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getAllCustomers`);
            //console.log("response", response);

            // BuyerOptions Filter TYPE = Buyer
            // const buyerOptions = response.data.result.filter((customer) => customer.TYPE === 'Buyer').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // // SellerOptions Filter TYPE = Seller
            // const sellerOptions = response.data.result.filter((customer) => customer.TYPE === 'Seller' || customer.TYPE === 'Partner').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // // SalesPersonOptions Filter TYPE = Sales Person
            // const salesPersonOptions = response.data.result.filter((customer) => customer.TYPE === 'Sales Person').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // PartnerOptions Filter TYPE = Partner
            const partnerOptions = response.data.result.filter((customer) => customer.TYPE === 'Partner').map((customer) => ({
                    value: customer.CUSTOMER_ID,
                    label: customer.NAME,
                }
            ));
            //
            // // HTByOptions Filter TYPE = HT By
            // const htByOptions = response.data.result.filter((customer) => customer.TYPE === 'Heat T').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // // CPByOptions Filter TYPE = CP By
            // const cpByOptions = response.data.result.filter((customer) => customer.TYPE === 'C&P').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // // PreformerOptions Filter TYPE = Preformer
            // const preformerOptions = response.data.result.filter((customer) => customer.TYPE === 'Preformer').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // // ETByOptions Filter TYPE = Electric
            // const etByOptions = response.data.result.filter((customer) => customer.TYPE === 'Electric').map((customer) => ({
            //         value: customer.CUSTOMER_ID,
            //         label: customer.NAME,
            //     }
            // ));
            //
            // this.setState({ buyerOptions, sellerOptions, salesPersonOptions, partnerOptions, htByOptions, cpByOptions, preformerOptions, etByOptions });
            this.setState({ partnerOptions });

            return response.data.result.map((customer) => ({
                value: customer.CUSTOMER_ID,
                label: customer.NAME,
            }));
        } catch (error) {
            console.error("Error fetching customer options:", error);
            return [];
        }
    }

    handleLoanRefSelect = async (value) => {
        try {
            const response = await axios.post(`${backendApiLink}/getLoanDetailsUsingId`, { LOANS_ID: value });
            console.log("response", response);
            this.setState({ loanRefDetails: response.data.result[0] });
            this.formRef.current.setFieldsValue({
                LOAN_AMOUNT: response.data.result[0].AMOUNT,
                PAYED_AMOUNT: response.data.result[0].PAYED_AMOUNT,
                DUE_AMOUNT: response.data.result[0].DUE_AMOUNT,
                CUSTOMER: response.data.result[0].CUSTOMER,
            }
            );
        } catch (error) {
            console.error("Error fetching loan ref details:", error);
        }
    }

    handleSubmit = async (values) => {
        try {
            // Retrieve USER_ID from rememberedUser
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }


            // Add IS_ACTIVE and CREATED_BY to the values object
            const updatedValues = {
                ...values,
                CREATED_BY: USER_ID,
            };
            //console.log('updatedValues', updatedValues);

            const response = await axios.post(`${backendApiLink}/addLoans`, updatedValues);

            if (response.data.success) {
                message.success('Loans added successfully');

                // Close the modal
                this.props.onClose();

                // Refresh the table
                this.props.refreshTable();

                // You can reset the form if needed
                this.formRef.current.resetFields();
            } else {
                message.error('Failed to add Loans');
            }
        } catch (error) {
            console.error('Error adding Loans:', error);
            message.error('Internal server error');
        }
    };

    render() {
        const { partnerOptions } = this.state;

        const disableStyle = {
            pointerEvents: 'none',
            width: '100%',
        }

        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="DATE"
                            label="Date"
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="TYPE"
                            label="Select Type"
                            rules={[{ required: true, message: 'Please select type' }]}
                            initialValue={this.props.loanType ? this.props.loanType : 'Get Loan'}
                        >
                            <Select
                                placeholder="Select Type"
                                allowClear
                                style={{ width: '100%' }}
                                showSearch
                                onChange={this.handleTypeChange}
                            >
                                <Option value="Get Loan">Get Loan</Option>
                                <Option value="Pay Loan">Pay Loan</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {this.state.showLoanRef && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                name="LOAN_REF"
                                label="Select Loan Ref"
                                rules={[{ required: true, message: 'Please select loan ref' }]}
                                initialValue={this.props.loanRef ? this.props.loanRef : null}
                            >
                                <Select
                                    placeholder="Select Loan Ref"
                                    allowClear
                                    style={{ width: '100%' }}
                                    showSearch
                                    options={this.state.loanRefOptions}
                                    filterOption={(input, option) =>
                                        (option.label
                                            ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            : false)
                                    }
                                    onSelect={ this.handleLoanRefSelect}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {this.state.showLoanRef && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={8}>
                            <Form.Item
                                name="LOAN_AMOUNT"
                                label="Loan Amount (RS)"
                            >
                                <InputNumber min={0} step={0.01} style={disableStyle}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8}>
                            <Form.Item
                                name="PAYED_AMOUNT"
                                label="Payed Amount (RS)"
                            >
                                <InputNumber min={0} step={0.01}  style={disableStyle}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8}>
                            <Form.Item
                                name="DUE_AMOUNT"
                                label="Due Amount (RS)"
                            >
                                <InputNumber min={0} step={0.01} style={disableStyle}/>
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="AMOUNT"
                            label="Amount (RS)"
                            rules={[{ required: true, message: 'Please enter amount' }]}
                        >
                            <InputNumber min={0} step={0.01} placeholder="Enter Amount" style={{ width : '100%' }}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="METHOD" label="Method"
                            rules={[{ required: true, message: 'Please select method' }]}
                                   initialValue={'Cash'}>
                            <Select
                                placeholder="Method"
                                style={{ width: '100%'}}
                                allowClear
                                showSearch
                            >
                                <Option value="Cash">Cash</Option>
                                <Option value="Bank">Bank</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="CUSTOMER" label="Customer"
                            rules={[{ required: true, message: 'Please select customer' }]} >
                            <Select
                                placeholder="Select Customer"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option.key
                                        ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false) ||
                                    (option.title
                                        ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false)
                                }
                            >
                                {partnerOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="REASON" label="Reason">
                            <Input.TextArea rows={4} placeholder="Enter remarks" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add Loans
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        );
    }
}

export default AddLoans;
