// /* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Row, Col, message, Select, Upload, Modal} from 'antd';
import axios from 'axios';
import {LeftOutlined, UploadOutlined} from "@ant-design/icons";
import backendApiLink from "../../../backendLinkConfig";

const { Option } = Select;


const UpdateHT = ({ initialValues, onUpdate, onCancel }) => {
    const [form] = Form.useForm();
    const [referenceOptions, setReferenceOptions] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [previewVisible2, setPreviewVisible2] = useState(false);
    const [imgBBLink2, setImgBBLink2] = useState('');


    useEffect(() => {
        // Set initial values when the form mounts
        form.setFieldsValue({
            ...initialValues,
            REFERENCE: initialValues.REFERENCE ? initialValues.REFERENCE.split(',').map(Number) : [],
    });
        fetchReferenceOptions();
        setInitialFileList();
    }, [form, initialValues]);

    const fetchReferenceOptions = async () => {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReference`);
            //console.log('response', response);
            const options = response.data.result.map((ref) => ({
                value: ref.ITEM_ID_AI,
                label: ref.CODE,
            }));
            setReferenceOptions(options);
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    };

    const setInitialFileList = () => {
        if (initialValues.PHOTO_LINK) {
            setFileList2([
                {
                    uid: '-1',
                    name: 'Group Photo',
                    status: 'done',
                    url: initialValues.PHOTO_LINK,
                },
            ]);
        }
        else {
            setFileList2([]);
        }
    }

    const handleFileChange = async ({ fileList }, uploaderNumber) => {
        try {
            setFileList2(fileList);

            if (fileList.length > 0) {
                const imgFile = fileList[0].originFileObj;

                if (imgFile) {
                    const formData = new FormData();
                    formData.append('image', imgFile);

                    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: {
                            key: 'a94bb5679f1add2d50baee0220cc7926', // Replace with your ImgBB API key
                        },
                    });

                    const imgBBLinkKey = `imgBBLink${uploaderNumber}`;
                    setImgBBLink2(response.data.data.url);
                    //show success message if response is success
                    if (response.data.success) {
                        message.success('Image uploaded successfully');
                    }
                    //show not success message if response is not success
                    else {
                        message.error('Failed to upload Image');
                    }
                    //console.log('Image uploaded to ImgBB:', response.data.data.url);

                    //console.log('this.state', this.state);
                }
            }
        } catch (error) {
            // Log the error to the console for debugging
            console.error('Error in handleFileChange:', error);

            // Handle specific errors if needed
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('ImgBB API Error:', error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from ImgBB API');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error in request setup:', error.message);
            }
        }
    };

    const countSelectedItems = () => {
        const selectedItems = form.getFieldValue('REFERENCE');
        form.setFieldsValue({ PIECES: selectedItems ? selectedItems.length : 0 });
    }


    const handleSubmit = async (values) => {
        try {
            // Add IS_ACTIVE to the values object
            const referenceString = Array.isArray(values.REFERENCE)
                ? values.REFERENCE.join(',')
                : values.REFERENCE ? values.REFERENCE.toString() : '';

            const updatedValues = {
                ...values,
                HT_ID: initialValues.HT_ID, // Pass the CUSTOMER_ID to identify the customer
                PHOTO_LINK: imgBBLink2 ? imgBBLink2 : initialValues.PHOTO_LINK,
                REFERENCE: referenceString,
            };

            // Make API call to update customer
            const response = await axios.post(`${backendApiLink}/updateHT`, updatedValues);

            if (response.data.success) {
                message.success('Treatment Group updated successfully');

                // Notify parent component (Treatment Group) about the update
                onUpdate();

                // Close the modal
                onCancel();

                // You can reset the form if needed
                form.resetFields();
            } else {
                message.error('Failed to update customer');
            }
        } catch (error) {
            console.error('Error updating customer:', error);
            message.error('Internal server error');
        }
    };

    const fileLimit = {
        maxFileSize: 2 * 1024 * 1024, // 2 MB
    }


    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                        name="NAME"
                        label="Group Name"
                    >
                        <Input placeholder="Enter a group name" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                        name="REFERENCE"
                        label="Reference"
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            mode="multiple"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option.key
                                    ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false) ||
                                (option.title
                                    ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false)
                            }
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item name="TYPE" label="Type"
                               rules={[{ required: true, message: 'Please select reference' }]}>
                        <Select
                            placeholder="Select Reference"
                            showSearch>
                            <Option value="Heat Treatment">Heat Treatment</Option>
                            <Option value="Electric Treatment">Electric Treatment</Option>
                            <Option value="C & P">C & P</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={22}>
                    <Form.Item name="PIECES" label="No of Pieces">
                        <Input type="number" placeholder="Enter no of pieces" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={2}>
                    <Form.Item
                        label=" "
                        name="COUNT"
                    >
                        <Button
                            type="default"
                            icon={<LeftOutlined />}
                            onClick={countSelectedItems}
                        >
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    {/* File Upload */}
                    <Form.Item
                        name="PHOTO"
                        label="Upload Group Photo"
                    >
                        <Upload
                            listType="picture-card"
                            fileList={fileList2}
                            beforeUpload={(file) => {
                                if (file.size > fileLimit.maxFileSize) {
                                    message.error('Image must be less than 2 MB');
                                    return false;
                                }
                                return true;
                            }
                            }
                            onPreview={() => setPreviewVisible2(true)}
                            onChange={(info) => handleFileChange(info, 2)}
                        >
                            {fileList2.length < 1 && '+ Upload'}
                        </Upload>
                        <Modal
                            visible={previewVisible2}
                            title="Group Photo"
                            footer={null}
                            onCancel={() => setPreviewVisible2(false)}
                        >
                            <img alt="Group Photo" style={{ width: '100%' }} src={fileList2.length > 0 ? fileList2[0].url : ''} />
                        </Modal>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                        name="REMARK"
                        label="Remarks"
                    >
                        <Input.TextArea rows={4} placeholder="Enter remarks" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Update Treatment Group
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default UpdateHT;
