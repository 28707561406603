// /* eslint-disable */

import React, { Component } from 'react';
import {
    Card,
    Row,
    Col,
    Select, Layout, Form, Button,
} from "antd";
import axios from "axios";
import backendApiLink from "../../backendLinkConfig";
import {SnippetsOutlined} from "@ant-design/icons";


const { Option } = Select;
const { Footer: AntFooter } = Layout;


class ItemURL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHeatTreated: false,
        isElectricTreated: false,
            isTransaction: false,
            fileList: [],
            gemType: null,
            customerOptions: [],
            buyerOptions: [],
            sellerOptions: [],
            salesPersonOptions: [],
            partnerOptions: [],
            htByOptions: [],
            cpByOptions: [],
            preformerOptions: [],
            etByOptions: [],
            heatTreatmentGroupOptions: [],
            ReferenceOptions: [],
            tableTransaction: [],
            isViewModalVisible: false,
            initialValues: {},

            enlargedImageVisible: false,
            enlargedImageVisibleHT: false,


            fileList1: [],  // For the first photo uploader
            previewVisible1: false,
            previewImage1: '',
            imgBBLink1: '',

            fileList2: [],  // For the second photo uploader
            previewVisible2: false,
            previewImage2: '',
            imgBBLink2: '',
        };
        this.getModelItemDetails();
    }

    formRef = React.createRef();



    async getModelItemDetails() {
        try {
            console.log('getModelItemDetails');
            console.log('this.props', this.props);
            const code = this.props.code ? this.props.code : this.props.match.params.code;
            // if(this.props.code){
            //     this.setState({ code: this.props.code });
            //     console.log('code1', this.props.code);
            // }
            // else {
            //     const { match: { params } } = this.props;
            //     const { code } = params;
            //
            //     this.setState({ code });
            //     console.log('code2', code);
            // }
            // const { match: { params } } = this.props;
            // const { code } = params;
            //
            this.setState({ code });
            console.log('code', code);

            // if (code) {
            //     //console.log('roughId1', code);
            //
            // }
            // const response = await axios.post(`${backendApiLink}/getItemDetailsUsingCode`, { code });
            const databaseNo = code.split('.')[0];
            const code1 = code.split('.')[1];
            let response = null;
            if (databaseNo === '1') {
                response = await axios.post(`https://nihalgems.online/api/getItemDetailsUsingCode`, { code: code1 });
            }
            else if (databaseNo === '2') {
                response = await axios.post(`https://nihalgems.online/api2/getItemDetailsUsingCode`, { code: code1 });
            }
            // const response = await axios.post(`https://nihalgems.online/api/getItemDetailsUsingCode`, { code });

            if (response.data.success) {
                const items = response.data.result;
                console.log('items', items);
                this.setState({ initialValues: items });
                //console.log('tableTransaction', this.state.initialValues);

                //set the form value
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }





    render() {
        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="center" align="middle">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card
                                className="criclebox tablespace mb-24"
                                title={this.state.initialValues.CODE}
                                style={{ width: '100%', paddingLeft: '10px', textAlign: 'center' }}
                            >
                                <Row gutter={[16, 16]} justify="center" align="middle">
                                    {this.state.initialValues.PHOTO_LINK && (
                                        <Col xs={18} sm={12} md={12} lg={3}>
                                            <img
                                                src={this.state.initialValues.PHOTO_LINK}
                                                alt="Item"
                                                style={{ width: '100%', borderRadius: '5%' }}
                                            />
                                        </Col>
                                    )}
                                    {this.state.initialValues.TYPE === 'Rough' && (
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.initialValues.ROUGH_TYPE}</span>
                                        </Col>
                                    )}
                                    {this.state.initialValues.TYPE === 'Lot' && (
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.initialValues.LOT_TYPE}</span>
                                        </Col>
                                    )}
                                    {this.state.initialValues.TYPE === 'Sorted Lots' && (
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.initialValues.SORTED_LOT_TYPE}</span>
                                        </Col>
                                    )}
                                    {this.state.initialValues.TYPE === 'Cut and Polished' && (
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.initialValues.CP_TYPE}</span>
                                        </Col>
                                    )}
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Weight - {this.state.initialValues.WEIGHT ? this.state.initialValues.WEIGHT : 'N/A'} ct</span>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Dimensions(HxWxL) - {this.state.initialValues.HEIGHT ? this.state.initialValues.HEIGHT : 'N/A'} x {this.state.initialValues.WIDTH ? this.state.initialValues.WIDTH : 'N/A'} x {this.state.initialValues.LENGTH ? this.state.initialValues.LENGTH : 'N/A'} mm</span>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Pieces - {this.state.initialValues.PIECES ? this.state.initialValues.PIECES : 'N/A'}</span>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Treatment- {this.state.initialValues.IS_HEAT_TREATED === 1 ? 'Heated' : 'Unheated'}</span>
                                    </Col>
                                    {this.state.initialValues.CERTIFICATE_PHOTO && (
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <a href={this.state.initialValues.CERTIFICATE_PHOTO} target="_blank" rel="noopener noreferrer">
                                                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Certificate Photo</span>
                                            </a>
                                        </Col>
                                    )}
                                    {this.state.initialValues.CERTIFICATE_LINK && (
                                        <Col xs={20} sm={20} md={12} lg={6}>
                                            <a href={this.state.initialValues.CERTIFICATE_LINK} target="_blank" rel="noreferrer">
                                                <Button type="default" icon={<SnippetsOutlined />} style={{ width: '100%' }}>
                                                    View Certificate
                                                </Button>
                                            </a>
                                        </Col>
                                    )}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <AntFooter style={{ background: "#fafafa" }}>
                        <Row justify="center" align="middle">
                            <Col xs={24} md={12} lg={12}>
                                <div className="copyright">
                                    © 2024 Nihal Gems Management System. All Rights Reserved. Copyright by
                                    <a href="https://nextech.lk/" target="_blank" rel="noopener noreferrer">Nextech Labs</a>
                                </div>
                            </Col>
                        </Row>
                    </AntFooter>
                </div>
            </>
        );
    }

}

export default ItemURL;
