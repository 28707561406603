// apiConfig.js
import Cookies from "js-cookie";

let backendApiLink = null;


let database = Cookies.get('database');

if (database) {
      // database = JSON.parse(database);
      //console.log('Database:', database);
      if (database === 'Database1'){
        backendApiLink = 'https://nihalgems.online/api';
        // backendApiLink = 'http://localhost:3001/api';
        //https://nihalgems.online/api
      }
      else if (database === 'Database2'){
        backendApiLink = 'https://nihalgems.online/api2';
      }
      else{
        console.error('Error: Invalid database');
      }
}


// const backendApiLink = '${backendApiLink}';

export default backendApiLink;
