// /* eslint-disable */
import React from 'react';
import {Form, Input, Button, Col, Row, message, Select, Upload, Modal} from 'antd';
import axios from "axios";
import Cookies from 'js-cookie';
import {LeftOutlined, RightOutlined, UploadOutlined} from "@ant-design/icons";
import backendApiLink from "../../../backendLinkConfig";
const { Option } = Select;


class AddHT extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            heatTreatmentGroupOptions: [],
            fileList2: [],  // For the second photo uploader
            previewVisible2: false,
            previewImage2: '',
            imgBBLink2: '',
        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions });
            const heatTreatmentGroupOptions = await this.fetchHTGroupOptions();
            this.setState({ heatTreatmentGroupOptions });
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReference`);
            //console.log('response', response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID_AI,
                label: ref.IS_IN_INVENTORY === 0 ? ref.CODE + " - (Not in Inventory)" : ref.CODE
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    async fetchHTGroupOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getAllHT`);
            //console.log("response", response);

            return response.data.result.map((ht) => ({
                value: ht.HT_ID,
                code: ht.CODE + " - " + ht.NAME,
            }));
        } catch (error) {
            console.error("Error fetching Elec Treatment Options:", error);
            return [];
        }
    }

    async setReferenceOptionsFromGroup(value){
        //get reference options from the selected heat treatment group and set all references to the reference Field
        const form = this.formRef.current;
        try {
            const response = await axios.post(`${backendApiLink}/getReferenceFromHTGroup`, {
                HT_ID: value,
            });
            console.log('response', response);
            form.setFieldsValue({ REFERENCE: response.data.result.map((ref) => ref.ITEM_ID_AI) });


        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    handleSubmit = async (values) => {
        try {
            // Retrieve USER_ID from rememberedUser
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }

            const referenceString = Array.isArray(values.REFERENCE)
                ? values.REFERENCE.join(',')
                : values.REFERENCE ? values.REFERENCE.toString() : '';

            // Add IS_ACTIVE and CREATED_BY to the values object
            const updatedValues = {
                ...values,
                CREATED_BY: USER_ID,
                REFERENCE: referenceString,
                PHOTO_LINK: this.state.imgBBLink2,
            };

            const response = await axios.post(`${backendApiLink}/addHT`, updatedValues);

            if (response.data.success) {
                message.success('Treatment Group added successfully');

                // Close the modal
                this.props.onClose();

                // Refresh the table
                this.props.refreshTable();

                // You can reset the form if needed
                this.formRef.current.resetFields();
            } else {
                message.error('Failed to add Treatment Group');
            }
        } catch (error) {
            console.error('Error adding Treatment Group:', error);
            message.error('Internal server error');
        }
    };

    handleFileChange = async ({ fileList }, uploaderNumber) => {
        try {
            this.setState({ [`fileList${uploaderNumber}`]: fileList, fileList: [] }); // Clear the general fileList

            if (fileList.length > 0) {
                const imgFile = fileList[0].originFileObj;

                if (imgFile) {
                    const formData = new FormData();
                    formData.append('image', imgFile);

                    const response = await axios.post('https://api.imgbb.com/1/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: {
                            key: 'a94bb5679f1add2d50baee0220cc7926', // Replace with your ImgBB API key
                        },
                    });

                    const imgBBLinkKey = `imgBBLink${uploaderNumber}`;
                    this.setState({ [imgBBLinkKey]: response.data.data.url });
                    //show success message if response is success
                    if (response.data.success) {
                        message.success('Image uploaded successfully');
                    }
                    //show not success message if response is not success
                    else {
                        message.error('Failed to upload Image');
                    }
                    //console.log('Image uploaded to ImgBB:', response.data.data.url);

                    //console.log('this.state', this.state);
                }
            }
        } catch (error) {
            // Log the error to the console for debugging
            console.error('Error in handleFileChange:', error);

            // Handle specific errors if needed
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('ImgBB API Error:', error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from ImgBB API');
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error in request setup:', error.message);
            }
        }
    };

    countSelectedItems = (value) => {
        const form = this.formRef.current;
        const selectedItems = form.getFieldValue('REFERENCE');
        const count = selectedItems ? selectedItems.length : 0;
        form.setFieldsValue({ PIECES: count });
    }


    render() {
        const { referenceOptions,fileList2,heatTreatmentGroupOptions } = this.state;
        const fileLimit = {
            accept: 'image/*', // Accept only image files
        };
        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="NAME"
                            label="Group Name"
                        >
                            <Input placeholder="Enter a group name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="REF_GROUP" label="Reference Group">
                            <Select
                                placeholder="Select Reference From Group"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option.key
                                        ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false) ||
                                    (option.title
                                        ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false)
                                }
                                onSelect={(value) => this.setReferenceOptionsFromGroup(value)}
                            >
                                {heatTreatmentGroupOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.code}>
                                        {option.code}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="REFERENCE" label="Reference"
                                   rules={[{ required: true, message: 'Please select reference' }]}>
                            <Select
                                placeholder="Select Reference"
                                mode="multiple"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    (option.key
                                        ? option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false) ||
                                    (option.title
                                        ? option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        : false)
                                }
                            >
                                {referenceOptions.map((option) => (
                                    <Option key={option.value} value={option.value} title={option.label}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item name="TYPE" label="Type"
                                   rules={[{ required: true, message: 'Please select reference' }]}>
                            <Select
                                placeholder="Select Reference"
                                showSearch>
                                <Option value="Heat Treatment">Heat Treatment</Option>
                                <Option value="Electric Treatment">Electric Treatment</Option>
                                <Option value="C & P">C & P</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={22}>
                        <Form.Item name="PIECES" label="No of Pieces">
                            <Input type="number" placeholder="Enter no of pieces" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={2}>
                        <Form.Item
                            label=" "
                            name="COUNT"
                        >
                            <Button
                                type="default"
                                icon={<LeftOutlined />}
                                onClick={this.countSelectedItems}
                            >
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        {/* File Upload */}
                        <Form.Item
                            name="PHOTO"
                            label="Upload Group Photo"
                        >
                            <Upload
                                customRequest={({ onSuccess, onError, file }) => {
                                    onSuccess();
                                }}
                                fileList={fileList2}
                                onChange={(info) => this.handleFileChange(info, 2)}
                                {...fileLimit}
                                listType="picture-card"
                                showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
                                maxCount={1} // Allow only one file
                                onPreview={() => this.setState({ previewVisible2: true })}
                            >
                                {fileList2.length >= 1 ? null : (
                                    <div>
                                        <UploadOutlined  />
                                        <div className="ant-upload-text">Upload</div>
                                    </div>
                                )}
                            </Upload>
                            {/* Display uploaded image */}
                            <div className="clearfix">
                                <Modal
                                    visible={this.state.previewVisible2}
                                    footer={null}
                                    onCancel={() => this.setState({ previewVisible2: false })}
                                >
                                    {this.state.imgBBLink2 === '' ? (
                                        <div className="loading-indicator">Uploading...</div>
                                    ) : (
                                        <img
                                            alt="Preview"
                                            style={{ width: '100%' }}
                                            src={this.state.imgBBLink2}
                                            onError={(e) => {
                                                console.error('Image loading error:', e);
                                            }}
                                        />
                                    )}
                                </Modal>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="REMARK"
                            label="Remarks"
                        >
                            <Input.TextArea rows={4} placeholder="Enter remarks" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add Treatment Group
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default AddHT;
