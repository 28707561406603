// /* eslint-disable */
import React, { useEffect, useState } from 'react';
import {Button, Card, Col, Divider, InputNumber, Modal, Popconfirm, Row, Table, Tooltip, Typography} from 'antd';
import axios from 'axios';
import backendApiLink from '../backendLinkConfig';
import LineChart from '../components/chart/LineChart';
import Paragraph from "antd/lib/typography/Paragraph";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, RightCircleOutlined} from "@ant-design/icons";
import Cookies from "js-cookie";
import {NavLink} from "react-router-dom";
import Search from "antd/es/input/Search";
import UpdateCutPolish from "./Operations/CutPolish/UpdateCutPolish";

const { Title } = Typography;

function Home() {
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState([]);
  const [todayTransactionData, setTodayTransactionData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isUpdateCPModalVisible, setIsUpdateCPModalVisible] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [dueTransactionData, setDueTransactionData] = useState([]);
    const [salesPersonItemData, setSalesPersonItemData] = useState([]);



  useEffect(() => {
    fetchData();
    fetchTodayTransactionData();
    fetchCPData();
    fetchDueTransactionData();
    fetchSalesPersonItemData();
  }, []);

  let rememberedUser = Cookies.get('rememberedUser');

  let NAME1 = "NO User";
  let ROLE1 = "USER";
  let CUSTOMER_ID1 = 0;

  if (rememberedUser) {
    rememberedUser = JSON.parse(rememberedUser);
    const { CUSTOMER_ID, NAME,ROLE } = rememberedUser;
    NAME1 = NAME;
    ROLE1 = ROLE;
    CUSTOMER_ID1 = CUSTOMER_ID;
    //console.log(`Name: ${NAME}`, `Role: ${ROLE}`, `Customer ID: ${CUSTOMER_ID}`);
  }
  else{
    Cookies.remove('rememberedUser');
    window.location.href = '/';
  }
  // let database = Cookies.get('database');
  //   if (database) {
  //       // database = JSON.parse(database);
  //       console.log('Database:', database);
  //   }


  const fetchData = async () => {
    setLoading(true);

    try {
        const response = await axios.post(`${backendApiLink}/getItemCountData`);


        if (response.data.success) {
        //console.log('ResponseDashboard:', response.data.result);
        setCountData(response.data.result);
      } else {
        //console.log('Error:', response.data.message);
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  };

    const fetchDueTransactionData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(`${backendApiLink}/getAllDueTransactions`);
            if (response.data.success) {
                //console.log('ResponseDashboard1:', response.data.result);
                setDueTransactionData(response.data.result);
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            setLoading(false);
        }
    }

    const fetchSalesPersonItemData = async () => {
        setLoading(true);

        try {
            const response = await axios.post(`${backendApiLink}/getSalesPersonItemData`);
            if (response.data.success) {
                //console.log('ResponseDashboard1:', response.data.result);
                setSalesPersonItemData(response.data.result);
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            setLoading(false);
        }
    }

  const fetchTodayTransactionData = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${backendApiLink}/getTodayTransactionData`);
        if (response.data.success) {
        //console.log('ResponseDashboard1:', response.data.result);
        setTodayTransactionData(response.data.result);
      } else {
        //console.log('Error:', response.data.message);
      }
    } catch (error) {
      //console.log('Error:', error.message);
    } finally {
      setLoading(false);
    }
  };

    const fetchCPData = async () => {

        setLoading(true);
        // if(ROLE1 !== "CP_USER") {
            try {
                const response = await axios.post(`${backendApiLink}/getCPDataForCPUser`, { CUSTOMER_ID: CUSTOMER_ID1 });
                if (response.data.success) {
                    //console.log('ResponseDashboard1:', response.data.result);
                    setTableData(response.data.result);
                } else {
                    //console.log('Error:', response.data.message);
                }
            } catch (error) {
                //console.log('Error:', error.message);
            } finally {
                setLoading(false);
            }
        // }
    }

    const handleUpdateShow = (row, type) => {
        setSelectedCustomer(row);
        setIsUpdateCPModalVisible(true);
    }

    const toggleUpdateCPModal = () => {
        setIsUpdateCPModalVisible(!isUpdateCPModalVisible);
    }



  const renderCountCards = () => {
    if (!countData) return null;

    return (
        <>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="circlebox">
              <div className="number">
                <Row gutter={[24, 0]}>

                  {/* Rough Section */}
                  {countData.roughCounts && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                            bordered
                            className="circlebox-subcard"
                            style={{
                              width: '100%',
                              height: 'auto', // Set 'auto' for mobile view
                              marginBottom: '16px',
                              background: 'linear-gradient(to right, #003BACFF, #5D91FFFF)',
                              padding: '16px',
                              flexDirection: 'column',
                              justifyContent: 'left',
                              alignItems: 'center',
                            }}
                        >
                          {(() => {
                            let total = 0;
                            for (let i = 0; i < countData.roughCounts.length; i++) {
                              total += countData.roughCounts[i].COUNT;
                            }
                            return <Title level={3} style={{ color: '#fff' }}> Total Roughs 🪨 - {total} </Title>;
                          })()}
                          <NavLink to="/rough">
                            <Paragraph style={{ color: '#dfeafb' }}>
                              More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
                            </Paragraph>
                          </NavLink>
                          <div className="chart-visitor-count">
                            {countData.roughCounts.map((v, index) => (
                                <div key={index}>
                                  <Title level={4} style={{ color: '#fff' }}>{v.ROUGH_TYPE} - {v.COUNT}</Title>
                                </div>
                            ))}
                          </div>
                        </Card>
                      </Col>
                  )}

                  {/* Lots Section */}
                  {countData.lotsCounts && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                            bordered
                            className="circlebox-subcard"
                            style={{
                              width: '100%',
                              height: 'auto', // Set 'auto' for mobile view
                              marginBottom: '16px',
                              background: 'linear-gradient(to right, #2A7200FF, #5E9D41FF)',
                              padding: '16px',
                              flexDirection: 'column',
                              justifyContent: 'left',
                              alignItems: 'center',
                            }}
                        >
                          {(() => {
                            let total = 0;
                            for (let i = 0; i < countData.lotsCounts.length; i++) {
                              total += countData.lotsCounts[i].COUNT;
                            }
                            return <Title level={3} style={{ color: '#fff' }}> Total Lots 📦 - {total} </Title>;
                          })()}
                          <NavLink to="/lots">
                            <Paragraph style={{ color: '#dfeafb' }}>
                              More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
                            </Paragraph>
                          </NavLink>
                          <div className="chart-visitor-count">
                            {countData.lotsCounts.map((v, index) => (
                                <div key={index}>
                                  <Title level={4} style={{ color: '#fff' }}>{v.LOT_TYPE} - {v.COUNT}</Title>
                                </div>
                            ))}
                          </div>
                        </Card>
                      </Col>
                  )}

                  {/* Sorted Lots Section */}
                  {countData.sortedLotsCounts && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                            bordered
                            className="circlebox-subcard"
                            style={{
                              width: '100%',
                              height: 'auto', // Set 'auto' for mobile view
                              marginBottom: '16px',
                              background: 'linear-gradient(to right, #2F294FFF, #534E6BFF)',
                              padding: '16px',
                              flexDirection: 'column',
                              justifyContent: 'left',
                              alignItems: 'center',
                            }}
                        >
                          {(() => {
                            let total = 0;
                            for (let i = 0; i < countData.sortedLotsCounts.length; i++) {
                              total += countData.sortedLotsCounts[i].COUNT;
                            }
                            return <Title level={3} style={{ color: '#fff' }}> Total Sorted Lots 📦 - {total} </Title>;
                          })()}
                          <NavLink to="/sorted-lots">
                            <Paragraph style={{ color: '#dfeafb' }}>
                              More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
                            </Paragraph>
                          </NavLink>
                          <div className="chart-visitor-count">
                            {countData.sortedLotsCounts.map((v, index) => (
                                <div key={index}>
                                  <Title level={4} style={{ color: '#fff' }}>{v.SORTED_LOT_TYPE} - {v.COUNT}</Title>
                                </div>
                            ))}
                          </div>
                        </Card>
                      </Col>
                  )}

                  {/* Cut and Polished Section */}
                  {countData.cutAndPolishedCounts && (
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Card
                            bordered
                            className="circlebox-subcard"
                            style={{
                              width: '100%',
                              height: 'auto', // Set 'auto' for mobile view
                              marginBottom: '16px',
                              background: 'linear-gradient(to right, #A81313FF, #A81313CB)',
                              padding: '16px',
                              flexDirection: 'column',
                              justifyContent: 'left',
                              alignItems: 'center',
                            }}
                        >
                          {(() => {
                            let total = 0;
                            for (let i = 0; i < countData.cutAndPolishedCounts.length; i++) {
                              total += countData.cutAndPolishedCounts[i].COUNT;
                            }
                            return <Title level={3} style={{ color: '#fff' }}> Total Cut and Polished 💎 - {total} </Title>;
                          })()}
                          <NavLink to="/c-p">
                            <Paragraph style={{ color: '#dfeafb' }}>
                              More Details <RightCircleOutlined style={{ color: '#dfeafb' }} />
                            </Paragraph>
                          </NavLink>
                          <div className="chart-visitor-count">
                            {countData.cutAndPolishedCounts.map((v, index) => (
                                <div key={index}>
                                  <Title level={4} style={{ color: '#fff' }}>{v.CP_TYPE} - {v.COUNT}</Title>
                                </div>
                            ))}
                          </div>
                        </Card>
                      </Col>
                  )}
                </Row>
              </div>
            </Card>
          </Col>
        </>
    );
  };

  const buttonStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };


  return (
      <>
        <div className="layout-header">
          <Title level={3} style={{ marginBottom: 20 }}>Welcome to Nihal Gems Management System, {NAME1}! 👋</Title>
        </div>

        <div className="layout-content">
          {ROLE1 === "ADMIN" ? (
          <Row gutter={[16, 16]} justify="left" align="top">
            {/*<Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">*/}
            {/*  <Card bordered={false} className="criclebox h-full">*/}
            {/*    <LineChart />*/}
            {/*  </Card>*/}
            {/*</Col>*/}
              <Col xs={24} sm={24} md={12} lg={12} xl={7} className="mb-24">
                  <Card bordered={false} className="criclebox h-full"
                        title={'Due Transactions'}>
                      <div className="table-responsive">
                          <Table
                              className="ant-border-space"
                              size="small"
                              style={{ margin: '15px' }}
                              rowKey="TRANSACTION_ID"
                              columns={[
                                  {
                                      title: 'Transaction Code',
                                      dataIndex: 'CODE',
                                  },
                                  {
                                      title: 'Customer Name',
                                      dataIndex: 'C_NAME',
                                  },
                                  {
                                      title: 'Due Days',
                                      dataIndex: 'DUE_DAYS',
                                      render: (text, record) => {
                                            return <div style={{ color: 'red' }}>{text} Days</div>;
                                        }
                                  }
                                  // },
                              ]}
                              dataSource={dueTransactionData}
                              pagination={{
                                  pageSize: 5
                              }}
                              loading={loading}
                          />

                      </div>
                  </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={7} className="mb-24">
                  <Card bordered={false} className="criclebox h-full"
                        title={'Items With Sales Persons'}>
                      <div className="table-responsive">
                          <Table
                              className="ant-border-space"
                              size="small"
                              style={{ margin: '15px' }}
                              rowKey="ITEM_ID_AI"
                              columns={[
                                  {
                                      title: 'Sales Person',
                                      dataIndex: 'C_NAME',
                                  },
                                  {
                                      title: 'Item Codes',
                                      dataIndex: 'CODES',
                                        render: (text, record) => {
                                            return <div>{text}</div>;
                                        }
                                  },
                              ]}
                              dataSource={salesPersonItemData}
                              pagination={{
                                  pageSize: 5
                              }}
                              loading={loading}
                          />

                      </div>
                  </Card>
              </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
              <Card bordered={false} className="criclebox h-full"
                    title={'Today\'s Transactions'}>
                <div className="table-responsive">
                  <Table
                      className="ant-border-space"
                      size="small"
                      style={{ margin: '15px' }}
                      rowKey="TRANSACTION_ID"
                      columns={[
                        {
                          title: 'Transaction Code',
                          dataIndex: 'CODE',
                          render: (text, record) => {
                            return (
                                <div style={{
                                  fontWeight: 'bold',
                                  color: (record.TYPE === 'Selling' || record.TYPE === 'S Payment' || record.TYPE === 'Get Loan') ? 'green' :
                                      (record.TYPE === 'Buying' || record.TYPE === 'B Payment' || record.TYPE === 'Expenses' || record.TYPE === 'Pay Loan') ? 'red' : 'inherit'
                                }}>
                                  {text}
                                </div>
                            );
                          }
                        },
                        {
                          title: 'Reference Item',
                          dataIndex: 'ITEM_CODE',
                        },
                        {
                          title: 'Customer Name',
                          dataIndex: 'C_NAME',
                        },
                        {
                          title: 'Amount',
                          dataIndex: 'PAYMENT_AMOUNT',
                          render: (text, record) => {
                            if (record.TYPE === 'Expenses' || record.TYPE === 'Buying' || record.TYPE === 'B Payment' || record.TYPE === 'Pay Loan') {
                              return <div style={{ color: 'red' }}>- Rs. {record.PAYMENT_AMOUNT}</div>;
                            } else {
                              return <div style={{ color: 'green' }}>+ Rs. {record.PAYMENT_AMOUNT}</div>;
                            }
                          },
                        },
                      ]}
                      dataSource={todayTransactionData}
                      pagination={{
                        pageSize: 5
                      }}
                      loading={loading}
                  />

                </div>
              </Card>
            </Col>
          </Row>
          ) : null}
          {ROLE1 !== "CP_USER" ? (
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            {renderCountCards()}
          </Row>
            ) : null}
          {ROLE1 === "CP_USER" ? (
              <Row gutter={[16, 16]} justify="left" align="top">
                <Col xs="24" xl={24}>
                  <Card
                      bordered={false}
                      className="criclebox tablespace mb-24"
                  >
                    <div className="table-responsive">
                      <Table
                          className="ant-border-space"
                          size="small"
                          style={{ margin: '15px' }}
                          rowKey="CP_ID"
                          columns={[
                            {
                              title: 'CP Code',
                              dataIndex: 'CODE',
                            },
                              {
                                    title: 'Status',
                                    dataIndex: 'IS_FINISHED_BY_CP',
                                    render: (text) => (
                                        <span>
                                            {text === 1 ? (
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        height: 'auto',
                                                        backgroundColor: 'green',
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    Marked Finished
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        height: 'auto',
                                                        backgroundColor: 'red',
                                                        pointerEvents: 'none',
                                                    }}
                                                >
                                                    Not Marked Finished
                                                </Button>
                                            )}
                                        </span>
                                    ),

                              },
                            {
                              title: 'Reference Item Code / Group',
                              dataIndex: 'REFERENCE_ITEM_CODE',
                              render: (text, record) => {
                                if (record.IS_GROUP === 1) {
                                  return (
                                      <span>
            <div>Group - {record.REFERENCE_GROUP_CODE}</div>
          </span>

                                  );
                                } else {
                                  return (

          <span>
            <div>{record.REFERENCE_ITEM_CODE}</div>
          </span>
                                  );
                                }
                              },
                            },
                            // {
                            //   title: 'Status',
                            //   dataIndex: 'IS_APPROVED',
                            //   render: (text) => (
                            //       <span>
                            //                             {text === 0 ? (
                            //                                 <Button
                            //                                     type="primary"
                            //                                     style={{
                            //                                       height: 'auto',
                            //                                       backgroundColor: 'red',
                            //                                       pointerEvents: 'none',
                            //                                     }}
                            //                                 >
                            //                                   Processing
                            //                                 </Button>
                            //                             ) : (
                            //                                 <Button
                            //                                     type="primary"
                            //                                     style={{
                            //                                       height: 'auto',
                            //                                       backgroundColor: 'green',
                            //                                       pointerEvents: 'none',
                            //                                     }}
                            //                                 >
                            //                                   Finished
                            //                                 </Button>
                            //                             )}
                            //                         </span>
                            //   ),
                            // },
            //                 {
            //                   title: 'CP By',
            //                   dataIndex: 'CP_BY_NAME',
            //                   render: (text, record) => (
            //                       <Button type="default" style={{ height: 'auto'  }} onClick={() => this.showCustomer(record.CUSTOMER_ID)}>
            //                     <span>
            //     <div>{record.CP_BY_NAME}</div>
            // </span>
            //                       </Button>
            //                   ),
            //                 },
                            {
                              title: 'No of Pieces',
                              dataIndex: 'PIECES',
                              render: (text, record) => {
                                if (record.IS_GROUP === 1) {
                                  return (
                                      <span>
                                                                <div>{record.REFERENCE_GROUP_PIECES}</div>
                                                            </span>
                                  );
                                } else {
                                  return (
                                      <span>
                                                                <div>N / A</div>
                                                            </span>
                                  );
                                }
                              },
                            },
                            {
                              title: 'Date Given',
                              dataIndex: 'DATE',
                              render: (row) => (
                                  row === null ? (
                                      <span>No Date</span>
                                  ) : (
                                      <span>{new Date(row).toLocaleDateString()}</span>
                                  )
                              ),
                            },
                            // {
                            //   title: 'Date Returned',
                            //   dataIndex: 'RETURN_DATE',
                            //   render: (row) => (
                            //       row === null ? (
                            //           <span>Not Returned</span>
                            //       ) : (
                            //           <span>{new Date(row).toLocaleDateString()}</span>
                            //       )
                            //   ),
                            // },
                            {
                              title: 'Action',
                              width: '120px',
                              align: 'center',
                              render: (row) => (
                                  <span style={{ display: 'flex', justifyContent: 'center' }}>

                                        <Tooltip title="Edit">
                                          <Button
                                              type="primary"
                                              icon={<EditOutlined />}
                                              size="large"
                                              style={buttonStyle}
                                              onClick={() => handleUpdateShow(row, 'edit')}
                                          />
                                        </Tooltip>
                          </span>
                              ),
                            },
                          ]}
                          dataSource={tableData}
                          pagination={false}
                      />
                    </div>
                      <Modal
                          title="Edit Cut & Polish"
                          visible={isUpdateCPModalVisible}
                          onCancel={toggleUpdateCPModal}
                          footer={null}
                          width={1100}
                      >
                          {selectedCustomer && (
                              <UpdateCutPolish
                                  key={selectedCustomer.CP_ID}
                                  initialValues={selectedCustomer}
                                  type='cp_user_edit'
                                  onUpdate={fetchCPData}
                                  onCancel={toggleUpdateCPModal}
                              />
                          )}
                      </Modal>
                  </Card>
                </Col>
              </Row>
            ) : null}
        </div>

      </>
  );
}

export default Home;
