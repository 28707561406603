/* eslint-disable */

import React, { Component } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tooltip
} from 'antd';
import {EditOutlined, DeleteOutlined, PlusOutlined, RightCircleOutlined} from '@ant-design/icons';
import axios from 'axios';
import AddLoans from './AddLoans';
import UpdateLoans from './UpdateLoans';
import Customer from "../../GlobalViewModels/Customer";
import backendApiLink from "../../../backendLinkConfig";

const { Search } = Input;
const { Option } = Select;


class Loan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isAddCustomerModalVisible: false,
            isUpdateCustomerModalVisible: false,
            selectedCustomer: null,
            referenceOptions: [],
            isViewModalCustomerVisible: false,
            loanType: 'Get Loan',
            loanRef: 0,

        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllCustomersHT = this.getAllCustomersHT.bind(this);
        this.toggleAddCustomerModal = this.toggleAddCustomerModal.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
        this.handleAddCustomer = this.handleAddCustomer.bind(this);
        this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);
        this.showCustomer = this.showCustomer.bind(this);
    }

    showCustomer(customerId){
        this.setState({
            selectedCustomer: customerId,
            isViewModalCustomerVisible: true,
        });
    }

    async componentDidMount() {
        this.getAllCustomersHT();
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({referenceOptions});
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    handleSearch = (value) => {
        const { tableData } = this.state;

        // Filter the table data based on Loans Code and Loans Name
        const filteredData = tableData.filter((record) => {
            return record.CODE.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if(filteredData.length === 0){
            message.info('No Loans Found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            // Make an API call to deactivate the Loans
            const response = await axios.post(`${backendApiLink}/deactivateLoans`, {
                LOANS_ID: Id,
            });

            if (response.data.success) {
                message.success('Loans deleted successfully');
                // Refresh the table
                this.getAllCustomersHT();
            } else {
                message.error('Failed to delete Loans');
            }
        } catch (error) {
            console.error('Error deleting Loans:', error);
            message.error('Internal server error');
        }
    };


    async getAllCustomersHT() {
        this.setState({ loading: true });

        try {
            const response = await axios.post(`${backendApiLink}/getAllLoans`);

            if (response.data.success) {
                const customers = response.data.result;

                this.setState({
                    tableData: customers,
                });
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleAddCustomerModal() {
        this.setState({
            isAddCustomerModalVisible: !this.state.isAddCustomerModalVisible,
        });
    }


    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    handleAddCustomer(values) {
        // Implement logic to add a new Loans using the provided values
        //console.log('Add Loans:', values);

        // Close the modal after adding Loans
        this.toggleAddCustomerModal();
    }

    handleUpdateCustomer() {
        // Notify the parent component to refresh the table
        this.getAllCustomersHT();

        // Close the update modal
        this.toggleUpdateCustomerModal();
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReference`);
            //console.log('response', response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID_AI,
                label: ref.CODE,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Loans"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by Loans Code"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                            allowClear
                                        />
                                        <Button
                                            className="primary"
                                            onClick={() => {
                                                this.toggleAddCustomerModal();
                                                this.setState({ loanType: 'Get Loan', loanRef: 0 });
                                            }}
                                            style={{ margin: '10px' }}
                                        >
                                            <PlusOutlined /> Add Loans
                                        </Button>

                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="CUSTOMER_ID"
                                        columns={[
                                            {
                                                title: 'Loans Code',
                                                dataIndex: 'CODE',
                                            },
                                            {
                                                title: 'Status',
                                                dataIndex: 'STATUS',
                                                render: (text, record) => {
                                                    return (
                                                        <>
                                                            {record.DUE_AMOUNT > 0 ? (
                                                                <Button
                                                                    type="primary"
                                                                    style={{
                                                                        height: 'auto',
                                                                        backgroundColor: 'red',
                                                                        pointerEvents: 'none',
                                                                    }}
                                                                >
                                                                    Pending
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="primary"
                                                                    style={{
                                                                        height: 'auto',
                                                                        backgroundColor: 'green',
                                                                        pointerEvents: 'none',
                                                                    }}
                                                                >
                                                                    Finished
                                                                </Button>
                                                            )}
                                                        </>
                                                    );
                                                },
                                            },
                                            {
                                                title: 'Date',
                                                dataIndex: 'DATE',
                                                render: (text, record) => (
                                                    <span>
                                                        {new Date(record.DATE).toLocaleDateString()}
                                                    </span>
                                                ),
                                            },
                                            {
                                                title: 'Customer Name',
                                                dataIndex: 'C_NAME',
                                                render: (text, record) => (
                                                    record.C_NAME ? (
                                                        <Button type="default" style={{ height: 'auto' }} onClick={() => this.showCustomer(record.CUSTOMER)}>
                <span>
                    <div>{record.C_NAME}</div>
                </span>
                                                        </Button>
                                                    ) : (
                                                        <span>N / A</span>
                                                    )
                                                ),
                                            },
                                            {
                                                title: 'Amount (RS)',
                                                dataIndex: 'AMOUNT',
                                                render: (text, record) => {
                                                    return <div>Rs. {parseFloat(record.AMOUNT).toFixed(2)}</div>;
                                                }
                                            },
                                            {
                                                title: 'Payed Amount (RS)',
                                                dataIndex: 'PAYED_AMOUNT',
                                                render: (text, record) => {
                                                    return <div style={{ color: 'green' }}>Rs. {parseFloat(record.PAYED_AMOUNT).toFixed(2)}</div>;
                                                }
                                            },
                                            {
                                                title: 'Remaining Amount (RS)',
                                                dataIndex: 'DUE_AMOUNT',
                                                render: (text, record) => {
                                                    return <div style={{ color: 'red' }}>Rs. {parseFloat(record.DUE_AMOUNT).toFixed(2)}</div>;
                                                }
                                            },

                                            // {
                                            //     title: 'Date',
                                            //     dataIndex: 'DATE',
                                            //     render: (text, record) => (
                                            //         <span>
                                            //             {new Date(record.DATE).toLocaleDateString()}
                                            //         </span>
                                            //     ),
                                            // },
                                            // {
                                            //     title: 'Reason',
                                            //     dataIndex: 'REASON',
                                            // },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Tooltip title="Pay Loan">
                    <Button
                        type="default"
                        icon={<RightCircleOutlined />}
                        size="large"
                        style={buttonStyle}
                        onClick={() => {
                            this.toggleAddCustomerModal();
                            this.setState({
                                loanType: 'Pay Loan',
                                loanRef: row.LOANS_ID
                            });
                        }}
                    />

                  </Tooltip>
                                                        <Divider
                                                            type="vertical"
                                                            style={{ height: '50px', display: 'flex', alignItems: 'center' }}
                                                        />
                            <Tooltip title="Edit">
                              <Button
                                  type="primary"
                                  icon={<EditOutlined />}
                                  size="large"
                                  style={buttonStyle}
                                  onClick={() => this.handleUpdateShow(row)}
                              />
                            </Tooltip>
                            <Divider type="vertical" style={{ height: '50px', display: 'flex', alignItems: 'center' }} />
                            <Tooltip title="Delete">
  <Popconfirm
      title="Are you sure you want to delete this Loans?"
      onConfirm={() => this.handleDelete(row.LOANS_ID)}
      okText="Yes"
      cancelText="No"
  >
    <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        size="large"
        style={buttonStyle}
    />
  </Popconfirm>
</Tooltip>
                          </span>
                                                ),
                                            },
                                        ]}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                        expandedRowRender={(record) => (
                                            record.PAYED_LOAN_DETAILS && record.PAYED_LOAN_DETAILS.length > 0 ? (
                                                <Table
                                                    size="small"
                                                    rowKey="LOANS_ID"
                                                    columns={[
                                                        {
                                                            title: 'Code',
                                                            dataIndex: 'CODE',
                                                        },
                                                        {
                                                            title: 'Date',
                                                            dataIndex: 'DATE',
                                                            render: (text, record) => (
                                                                <span>
                                                                    {new Date(record.DATE).toLocaleDateString()}
                                                                </span>
                                                            ),
                                                        },
                                                        {
                                                            title: 'Amount (RS)',
                                                            dataIndex: 'AMOUNT',
                                                            render: (text, record) => {
                                                                return <div>Rs. {parseFloat(record.AMOUNT).toFixed(2)}</div>;
                                                            }
                                                        },
                                                        {
                                                            title: 'Method',
                                                            dataIndex: 'METHOD',
                                                        },
                                                        {
                                                            title: 'Reason',
                                                            dataIndex: 'REASON',
                                                        }
                                                    ]}
                                                    dataSource={record.PAYED_LOAN_DETAILS}
                                                    pagination={false}
                                                >
                                                </Table>
                                            ) : null
                                        )}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Loans"
                    visible={this.state.isAddCustomerModalVisible}
                    onCancel={this.toggleAddCustomerModal}
                    footer={null}
                >
                    <AddLoans
                        onClose={this.toggleAddCustomerModal}
                        refreshTable={this.getAllCustomersHT}
                        loanType={this.state.loanType}
                        loanRef={this.state.loanRef}
                        key={this.state.loanRef}
                    />
                </Modal>

                {/* Update Loans Modal */}
                <Modal
                    title="Update Loans"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    footer={null}
                >
                    {this.state.selectedCustomer && (
                        <UpdateLoans
                            initialValues={this.state.selectedCustomer}
                            onUpdate={this.getAllCustomersHT}
                            onCancel={this.toggleUpdateCustomerModal}
                        />
                    )}
                </Modal>

                <Modal
                    title="View Customer"
                    visible={this.state.isViewModalCustomerVisible}
                    onCancel={() => this.setState({ isViewModalCustomerVisible: false })}
                    footer={null}
                    width={1250}
                >
                    {this.state.selectedCustomer && (
                        <Customer
                            key={this.state.selectedCustomer} // Pass a key to ensure a new instance is created
                            customerId={this.state.selectedCustomer}
                        />
                    )}
                </Modal>
            </>
        );
    }
}

export default Loan;
