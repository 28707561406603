// /* eslint-disable */
import React, { Component } from 'react';
import {Button, Card, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Table, Tooltip} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import AddHT from './AddHT';
import UpdateHT from './UpdateHT';
import Item from "../../GlobalViewModels/Item";
import backendApiLink from "../../../backendLinkConfig";


const { Option } = Select;

const { Search } = Input;

class HT extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isAddCustomerModalVisible: false,
            isUpdateCustomerModalVisible: false,
            selectedCustomer: null,
            referenceOptions: [],
            isViewItemModalVisible: false,
            selectedRefferenceItem: null,
        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllCustomersHT = this.getAllCustomersHT.bind(this);
        this.toggleAddCustomerModal = this.toggleAddCustomerModal.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
        this.handleAddCustomer = this.handleAddCustomer.bind(this);
        this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);

    }

    async componentDidMount() {
        this.getAllCustomersHT();
        const referenceOptions = await this.fetchReferenceOptions();
        this.setState({referenceOptions});
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post(`${backendApiLink}/getItemsForReference`);
            //console.log('response', response);
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID_AI,
                label: ref.CODE,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    handleSearch = (value) => {
        const { tableData } = this.state;

        // Filter the table data based on Treatment Group Code and Treatment Group Name
        const filteredData = tableData.filter((record) => {
            return record.CODE.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if(filteredData.length === 0){
            message.info('No Treatment Group Found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            // Make an API call to deactivate the Treatment Group
            const response = await axios.post(`${backendApiLink}/deactivateHT`, {
                HT_ID: Id,
            });

            if (response.data.success) {
                message.success('Treatment Group deleted successfully');
                // Refresh the table
                this.getAllCustomersHT();
            } else {
                message.error('Failed to delete Treatment Group');
            }
        } catch (error) {
            console.error('Error deleting Treatment Group:', error);
            message.error('Internal server error');
        }
    };

    showReferenceItem(itemId){
        //console.log('itemId', itemId);
        this.setState({
            selectedRefferenceItem: itemId,
            isViewItemModalVisible: true,
        });
    }


    async getAllCustomersHT() {
        this.setState({ loading: true });

        try {
            const response = await axios.post(`${backendApiLink}/getAllHT`);

            if (response.data.success) {
                const customers = response.data.result;

                this.setState({
                    tableData: customers,
                });
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    toggleAddCustomerModal() {
        this.setState({
            isAddCustomerModalVisible: !this.state.isAddCustomerModalVisible,
        });
    }

    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    handleAddCustomer(values) {
        // Implement logic to add a new Treatment Group using the provided values
        //console.log('Add Treatment Group:', values);

        // Close the modal after adding Treatment Group
        this.toggleAddCustomerModal();
    }

    handleUpdateCustomer() {
        // Notify the parent component to refresh the table
        this.getAllCustomersHT();

        // Close the update modal
        this.toggleUpdateCustomerModal();
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Treatment Group"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by Group Code"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                        />
                                        <Button className="primary" onClick={this.toggleAddCustomerModal} style={{ margin: '10px' }}>
                                            <PlusOutlined /> Add Treatment Group
                                        </Button>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="HT_ID"
                                        columns={[
                                            {
                                                title: 'Group Code',
                                                dataIndex: 'CODE',
                                            },
                                            {
                                                title: 'Group Name',
                                                dataIndex: 'NAME',
                                            },
                                            {
                                                title: 'Type',
                                                dataIndex: 'TYPE'
                                            },
                                            {
                                                title: 'No of Pieces',
                                                dataIndex: 'PIECES'
                                            },
                                            {
                                                title: "References",
                                                dataIndex: "REFERENCE",
                                                width: '40%',
                                                render: (text, record) => {
                                                    const designationIds = text ? text.split(",").map(Number) : [];
                                                    const visibleDesignationIds = designationIds.slice(0, 5); // Limit to 4 buttons
                                                    const remainingDesignationIds = designationIds.slice(5); // Remaining designationIds

                                                    const showRemainingDesignations = () => {
                                                        Modal.info({
                                                            title: "Remaining References",
                                                            content: (
                                                                <div>
                                                                    {remainingDesignationIds.map((designationId, index) => (
                                                                        <Button
                                                                            key={index}
                                                                            type="default"
                                                                            style={{ height: 'auto' }}
                                                                            onClick={() => {
                                                                                this.showReferenceItem(designationId);
                                                                                hideRemainingDesignations();
                                                                            }}
                                                                        >
                        <span>
                            <div>{this.state.referenceOptions.find((option) => option.value === designationId)?.label}</div>
                        </span>
                                                                        </Button>
                                                                    ))}
                                                                </div>
                                                            ),
                                                        });
                                                    };
                                                    const hideRemainingDesignations = () => {
                                                        Modal.destroyAll();
                                                    };

                                                    return (
                                                        <>
                                                            {visibleDesignationIds.map((designationId, index) => (
                                                                <Button
                                                                    key={index}
                                                                    type="default"
                                                                    style={{ height: 'auto' }}
                                                                    onClick={() => this.showReferenceItem(designationId)}
                                                                >
                        <span>
                            <div>{this.state.referenceOptions.find((option) => option.value === designationId)?.label}</div>
                        </span>
                                                                </Button>
                                                            ))}

                                                            {remainingDesignationIds.length > 0 && (
                                                                <Button
                                                                    type="default"
                                                                    style={{ height: 'auto' }}
                                                                    onClick={showRemainingDesignations}
                                                                >
                        <span>
                            <div>+</div>
                        </span>
                                                                </Button>
                                                            )}
                                                        </>
                                                    );
                                                },
                                            },
                                            {
                                                title: 'Remarks',
                                                dataIndex: 'REMARK',
                                            },
                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tooltip title="Edit">
                              <Button
                                  type="primary"
                                  icon={<EditOutlined />}
                                  size="large"
                                  style={buttonStyle}
                                  onClick={() => this.handleUpdateShow(row)}
                              />
                            </Tooltip>
                            <Divider type="vertical" style={{ height: '50px', display: 'flex', alignItems: 'center' }} />
                            <Tooltip title="Delete">
  <Popconfirm
      title="Are you sure you want to delete this Treatment Group?"
      onConfirm={() => this.handleDelete(row.HT_ID)}
      okText="Yes"
      cancelText="No"
  >
    <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        size="large"
        style={buttonStyle}
    />
  </Popconfirm>
</Tooltip>
                          </span>
                                                ),
                                            },
                                        ]}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Add Treatment Group"
                    visible={this.state.isAddCustomerModalVisible}
                    onCancel={this.toggleAddCustomerModal}
                    footer={null}
                >
                    <AddHT
                        onClose={this.toggleAddCustomerModal}
                        refreshTable={this.getAllCustomersHT}
                    />
                </Modal>

                {/* Update Treatment Group Modal */}
                <Modal
                    title="Update Treatment Group"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    footer={null}
                >
                    {this.state.selectedCustomer && (
                        <UpdateHT
                            initialValues={this.state.selectedCustomer}
                            onUpdate={this.getAllCustomersHT}
                            onCancel={this.toggleUpdateCustomerModal}
                        />
                    )}
                </Modal>
                <Modal
                    title="View Item"
                    visible={this.state.isViewItemModalVisible}
                    onCancel={() => this.setState({ isViewItemModalVisible: false })}
                    footer={null}
                    width={1250}
                >
                    {this.state.selectedRefferenceItem && (
                        <Item
                            key={this.state.selectedRefferenceItem} // Pass a key to ensure a new instance is created
                            itemId={this.state.selectedRefferenceItem}
                        />
                    )}
                </Modal>
            </>
        );
    }
}

export default HT;
