import React, { Component } from 'react';
import {Button, Card, Col, Form, Row, Progress, InputNumber} from 'antd';
import axios from 'axios';
import backendApiLink from "../../backendLinkConfig";
import LineChart from "../../components/chart/LineChart";
import moment from "moment";

class HeatTreatmentOven extends Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            loading: false,
            currentTemperature: 0, // Assuming this is where you store the current temperature
            updatedTime: '2021-09-01 12:00:00',
            machineStatus: 'ON',
        };

        this.getLastTemperature = this.getLastTemperature.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.getLastTemperature();
    }

    async getLastTemperature() {
        this.setState({ loading: true });

        try {
            const response = await axios.post(`${backendApiLink}/getLastTemperature`);

            if (response.data.success) {
                console.log('Response:', response.data);
                const { result } = response.data;
                const { TEMP } = result[0];
                //updatedTime 2024-06-01T07:12:42.000Z want to convert to 2024-06-01 07:12:42 and add 05:30 hours
                const updatedTime1 = new Date(result[0].CREATED_DATE).toISOString().replace('T', ' ').slice(0, 19);

                this.setState({
                    currentTemperature: TEMP,
                    updatedTime: updatedTime1,
                });

                //set SetTemperature value as SET_VALUE
                this.formRef.current.setFieldsValue({
                    SetTemperature: result[0].SET_VALUE,
                });

                //set machine status off if CREATED_DATE older than 3 hours else ON
                const currentTime = new Date();
                const updatedTime = new Date(result[0].CREATED_DATE);
                const diff = Math.abs(currentTime - updatedTime) / 36e5;
                if (diff > 3) {
                    this.setState({
                        machineStatus: 'OFF',
                    });
                } else {
                    this.setState({
                        machineStatus: 'ON',
                    });
                }
            } else {
                //console.log('Error:', response.data.message);
            }
        } catch (error) {
            //console.log('Error:', error.message);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    handleSubmit(values) {
        console.log('Success:', values);
        // Call changeSetTemperature API
        this.setState({ loading: true });

        try {
            const response = axios.post(`${backendApiLink}/changeSetTemperature`, {
                SET_VALUE: values.SetTemperature,
            }
            );

            if (response.data.success) {
                console.log('Response:', response.data);
                this.getLastTemperature();
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    render() {
        const { currentTemperature, updatedTime } = this.state;
        const progressPercent = (currentTemperature / 1900) * 100;
        const progressStatus = currentTemperature > 1000 ? 'exception' : 'normal'; // Change color if over 1000

        return (
            <>
                {/* Cards and Tables */}
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="center">
                        <Col xs={24} xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                style={{ padding: '20px' }}
                                title='Oven 1'
                            >
                                <div className="table-responsive">
                                    <Row gutter={[16, 16]} justify="left" align="top">
                                        <Col xs={24} xl={6}>
                                            <Card
                                                bordered={false}
                                                title='Current Temperature'
                                                align="center"
                                                className="criclebox tablespace mb-24"
                                            >
                                                <Progress
                                                    type="circle"
                                                    percent={progressPercent}
                                                    status={progressStatus}
                                                    style={{ width: '100%', margin: '20px' }}
                                                    format={percent => `${((percent / 100) * 1900).toFixed(1)}°C`}
                                                />
                                                <p>Last Updated: {moment(updatedTime).add(5, 'hours').add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')}</p>
                                                {this.state.machineStatus === 'OFF' && <p style={{ color: 'red' }}>Machine Can be OFF Because of inactivity of more than 3 hours</p>}
                                            </Card>
                                        </Col>
                                        <Col xs={24} xl={6}>
                                            <Row gutter={[16, 16]} justify="center">
                                            <Card
                                                className="criclebox tablespace mb-24"
                                                title='Set Temperature'
                                            >
                                                <Form
                                                    layout="vertical"
                                                    onFinish={this.handleSubmit}
                                                    style={{ margin: '20px' }}
                                                    ref={this.formRef}
                                                >
                                                    <Form.Item
                                                        name="SetTemperature"
                                                        rules={[{ required: true, message: 'Please input temperature!' }]}
                                                        initialValue={100}
                                                    >
                                                        <InputNumber
                                                            min={1} // Set the minimum value (adjust as needed)
                                                            max={1900} // Set the maximum value to 1900
                                                            placeholder="Temperature"
                                                            style={{ width: '100%' }} // Make sure the input fits the form item width
                                                        />
                                                    </Form.Item>
                                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                                        Set
                                                    </Button>
                                                </Form>

                                            </Card>
                                            </Row>
                                            <Row gutter={[16, 16]} justify="center">
                                                <Button type="primary"  onClick={this.getLastTemperature} size={'large'} style={{ margin: '20px' , width : '100%'}}>
                                                    Refresh
                                                </Button>
                                            </Row>

                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Card style={{ padding: '20px' }}
                                                className="criclebox tablespace mb-24">
                                                <LineChart/>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default HeatTreatmentOven;
