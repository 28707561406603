// Get current date
const currentDate = new Date();
console.log('currentDate:', currentDate);

// Generate an array of the last 15 days in 'D MMM' format, ending with today
const last15Days = Array.from({ length: 15 }, (_, i) => {
  const date = new Date();
  date.setDate(currentDate.getDate() - (14 - i)); // Adjust index to ensure today is the last element
  return date.toLocaleDateString('default', { day: 'numeric', month: 'short' });
});
console.log('last15Days:', last15Days);

// Example data for the series
const lineChart = {
  series: [
    {
      name: "Total Sell Amount",
      data: [350, 40, 300, 220, 500, 250, 400, 230, 500, 250, 400, 230, 350, 40, 300],
      offsetY: 0,
    },
    {
      name: "Total Buy Amount",
      data: [30, 90, 40, 140, 290, 290, 340, 230, 400, 200, 300, 130, 30, 90, 40],
      offsetY: 0,
    },
  ],

  options: {
    chart: {
      width: "100%",
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },

    legend: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: ["#8c8c8c"],
        },
      },
    },

    xaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontWeight: 600,
          colors: Array(15).fill("#8c8c8c"),
        },
      },
      categories: last15Days,
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val + " °C";
        },
      },
    },
  },
};

export default lineChart;
